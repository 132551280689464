import { usePrivy } from '@privy-io/react-auth';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

// Hooks
import { usePoapsQueryContext } from 'lib/hooks/usePoapsQuery';

// Constants
import { ROUTES } from 'lib/helpers/constants';

// UI
import MainLayout from 'ui/layouts/MainLayout';
import useSignMessage from '../../lib/hooks/useSignMessage';
import PageTitle from './components/PageTitle';
import SignatureForm from './components/SignatureForm';
import UserTokens from './components/UserTokens';

// Types
import { UserPoap } from 'lib/types';

// Styled Components
const Wrapper = styled.div`
  padding: 50px 0 100px;
`;

const UserSignature: FC = () => {
  const { push } = useHistory();
  const { authenticated, user, logout } = usePrivy();
  const { poaps } = usePoapsQueryContext();
  const { signMessage } = useSignMessage();

  useEffect(() => {
    if (!authenticated) push(ROUTES.home);
  }, [authenticated, push]);

  return (
    <MainLayout>
      <Wrapper>
        <PageTitle tokens={poaps as UserPoap[]} />

        <SignatureForm
          account={user?.wallet?.address || ''}
          tokens={poaps as UserPoap[]}
          fallbackAction={logout}
          mainAction={signMessage}
        />

        <UserTokens tokens={poaps as UserPoap[]} />
      </Wrapper>
    </MainLayout>
  )
};

export default UserSignature;
