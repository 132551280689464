import styled from '@emotion/styled';

const Content = styled.div`
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default Content;
