export const POAPS_FOR_COLLECTOR = /* GraphQL */ `
  query POAPsForCollector($where: poaps_bool_exp!) {
    poaps(limit: 20, order_by: { id: desc }, where: $where) {
      id
      drop {
        id
        created_date
        end_date
        start_date
        image_url
        expiry_date
        name
      }
    }
  }
`

export interface POAPsForCollectorsCompassData {
  data: {
    poaps: {
      id: number
      drop: {
        id: number
        created_date: string
        end_date: string
        start_date: string
        image_url: string
        expiry_date: string
        name: string
      }
    }[]
  }
}
