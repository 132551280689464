import constate from 'constate';
import { usePrivy } from '@privy-io/react-auth'

// Hooks
import { useQuery } from 'react-query';
import { getPOAPsForCollector } from '../services/tokens/token.service';

const usePoapsQuery = () => {
  const { user } = usePrivy();
  const address = user?.wallet?.address || '';

  const { data: poaps = [], isLoading: isFetchingPoaps } = useQuery(
    ['getPOAPsForCollector', address],
    () => getPOAPsForCollector(address),
    {
      enabled: Boolean(address),
    },
  )

  return { poaps, isFetchingPoaps };
};

const [PoapsQueryProvider, usePoapsQueryContext] = constate(usePoapsQuery);

export { PoapsQueryProvider, usePoapsQueryContext };
