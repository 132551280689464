import { usePrivy } from '@privy-io/react-auth';
import React, { FC } from 'react';

// Hooks
import { usePoapsQueryContext } from 'lib/hooks/usePoapsQuery';

// UI components
import Layout from 'ui/styled/Layout';
import Content from 'ui/styled/Content';
import Header from 'ui/components/Header';
import Footer from 'ui/components/Footer';

const MainLayout: FC = ({ children }) => {
  const { authenticated, user, logout, login } = usePrivy();
  const { poaps } = usePoapsQueryContext();

  return (
    <Layout>
      <Header
        isConnected={authenticated}
        connectAction={login}
        disconnectAction={logout}
        account={user?.wallet?.address || ''}
        tokens={poaps}
      />

      <Content>{children}</Content>

      <Footer />
    </Layout>
  )
};

export default MainLayout;
