import {LocalEvent} from 'lib/types';

export const ROUTES = {
  home: '/',
  userSignature: '/user',
};

export const EVENTS: LocalEvent[] = [
  {
    name: 'Strawberry Sundae',
    image: 'https://storage.googleapis.com/poapmedia/strawberry-sundae-2021-logo-1610645726320.png'
  },
  {
    name: 'Ecclectic Methods TokenSmart show',
    image: 'https://storage.googleapis.com/poapmedia/ecclectic-methods-tokensmart-show-2021-logo-1610524784623.png'
  },
  {
    name: 'Metalympics - Cryptokitties',
    image: 'https://storage.googleapis.com/poapmedia/metalympics-cryptokitties-2021-logo-1610524387440.png'
  },
  {
    name: 'Core HQ - The Arrival - Decentraland',
    image: 'https://storage.googleapis.com/poapmedia/core-hq-the-arrival-2021-logo-1610513299215.png'
  },
  {
    name: '1ST Live In-World AMA in Chateau Satoshi with @baus, Project Lead of Decentral Games 01/13/2021',
    image: 'https://storage.googleapis.com/poapmedia/1st-live-in-world-ama-in-chateau-satoshi-with-baus-project-lead-of-decentral-games-01132021-2021-logo-1610512639125.png'
  },
  {
    name: 'DRAGONATE: Thou art the new POAP Mastr',
    image: 'https://storage.googleapis.com/poapmedia/dragonate-thou-art-the-new-poap-mastr-2021-logo-1610487538375.png'
  },
  {
    name: 'NO PROTOCOL on Uniswap',
    image: 'https://storage.googleapis.com/poapmedia/no-protocol-on-uniswap-2021-logo-1610490377020.png'
  },
  {
    name: 'Weekly AVASTARS Giveaway! 1/12/2021',
    image: 'https://storage.googleapis.com/poapmedia/weekly-avastars-giveaway-1122021-2021-logo-1610390972359.png'
  },
  {
    name: 'Aavegotchi: TokenSmart Special Event!',
    image: 'https://storage.googleapis.com/poapmedia/aavegotchi-tokensmart-special-event-2021-logo-1610388940325.png'
  },
  {
    name: 'Chicago Ethereum - Eth2 staking with Superphiz and Michael Giesen',
    image: 'https://storage.googleapis.com/poapmedia/chicago-ethereum-eth2-staking-with-superphiz-and-michael-giesen-2021-logo-1610223862174.png'
  },
  {
    name: 'Dark Forest: Beginners introduction to creating plugins in javascript',
    image: 'https://storage.googleapis.com/poapmedia/dark-forest-beginners-introduction-to-creating-plugins-in-javascript-2021-logo-1610217059543.png'
  },
  {
    name: 'Beacon Chain First 1024 Depositors and Proposers',
    image: 'https://storage.googleapis.com/poapmedia/beacon-chain-first-1024-depositors-and-proposers-2021-logo-1610143811247.png'
  },
  {
    name: 'NFTs with cloudwhite of OpenSea at Madison Blockchain',
    image: 'https://storage.googleapis.com/poapmedia/nfts-with-cloudwhite-of-opensea-at-madison-blockchain-2021-logo-1610140355284.png'
  },
  {
    name: 'STAY HOME, PROTECT THE NHS, SAVE LIVES - #1',
    image: 'https://storage.googleapis.com/poapmedia/stay-home-protect-the-nhs-save-lives-1-2021-logo-1610117390703.png'
  },
  {
    name: 'Easy Street, WIP crew fire side chat',
    image: 'https://storage.googleapis.com/poapmedia/easy-street-wip-crew-fire-side-chat-2021-logo-1610004137283.png'
  },
  {
    name: 'BrightID Community Call & AMA # 3 - RabbitHole',
    image: 'https://storage.googleapis.com/poapmedia/brightid-community-call-ama-2-rabbithole-2021-logo-1609995677193.png'
  },
  {
    name: 'DIGITALAX 07-01-21',
    image: 'https://storage.googleapis.com/poapmedia/digitalax-07-01-21-2021-logo-1609981822522.png'
  },
  {
    name: 'StrawberrySith Sundaes',
    image: 'https://storage.googleapis.com/poapmedia/strawberrysith-sundaes-2021-logo-1609973386815.png'
  },
  {
    name: 'ECLECTIC METHOD',
    image: 'https://storage.googleapis.com/poapmedia/eclectic-method-2021-logo-1609973285312.png'
  },
  {
    name: 'Metalympics 2021: AxieInfinity',
    image: 'https://storage.googleapis.com/poapmedia/metalympics-2021-axieinfinity-2021-logo-1609973134548.png'
  },
  {
    name: 'THE WIP 2021: The first WIP of 2021: Artblocks, Alethea, Ephimera, and Axie Infinity',
    image: 'https://storage.googleapis.com/poapmedia/the-wip-2021-the-first-wip-of-2021-artblocks-alethea-ephimera-and-axie-infinity-2021-logo-1609973081561.png'
  },
  {
    name: 'xDai STAKE Super Delegator: First 100',
    image: 'https://storage.googleapis.com/poapmedia/xdai-stake-super-delegator-first-100-2021-logo-1610121119846.png'
  },
  {
    name: 'TE Academy - Introduction to Token Engineering - 01/2021',
    image: 'https://storage.googleapis.com/poapmedia/te-academy-introduction-to-token-engineering-012021-2021-logo-1609950025894.png'
  },
  {
    name: 'DragoNate POAP master test',
    image: 'https://storage.googleapis.com/poapmedia/dragonate-poap-master-test-2021-logo-1609914084507.png'
  },
  {
    name: 'YOLO @ 1620 Launch',
    image: 'https://storage.googleapis.com/poapmedia/yolo-1620-launch-2021-logo-1609895296389.png'
  },
  {
    name: 'Decentraleo 2021',
    image: 'https://storage.googleapis.com/poapmedia/decentraleo-2021-2021-logo-1609860711418.png'
  },
  {
    name: 'Foxyoga Listening Party: The Matthew and Rizzle Show!',
    image: 'https://storage.googleapis.com/poapmedia/foxyoga-listening-party-the-matthew-and-rizzle-show-2021-logo-1609972778590.png'
  },
  {
    name: 'DABDRAGON HANGOUTS 1/5/2021',
    image: 'https://storage.googleapis.com/poapmedia/dabdragon-hangouts-152021-2021-logo-1609787501893.png'
  },
  {
    name: 'Singularities- Mich DCL Gallery Show 2021',
    image: 'https://storage.googleapis.com/poapmedia/singularities-mich-dcl-gallery-show-2021-2021-logo-1609787500553.png'
  },
  {
    name: 'Weekly AVASTARS Giveaway! 1/5/2021',
    image: 'https://storage.googleapis.com/poapmedia/weekly-avastars-giveaway-152021-2021-logo-1609787468297.png'
  },
  {
    name: 'Ezincryptos Listening Party: TokenSmart Podcast',
    image: 'https://storage.googleapis.com/poapmedia/ezincryptos-listening-party-tokensmart-podcast-2021-logo-1609787429928.png'
  },
  {name: 'Zen Fest', image: 'https://storage.googleapis.com/poapmedia/zen-fest-2021-logo-1609752832771.png'},
  {
    name: 'ETH Network - Attendee (Gen 19)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-19-2021-logo-1609663235221.png'
  },
  {
    name: 'Thank you Community Leads! This has been an EPIC 2020 Thanks to YOU!',
    image: 'https://storage.googleapis.com/poapmedia/thank-you-community-leads-this-has-been-an-epic-2020-thanks-to-you-2020-logo-1609365102867.png'
  },
  {
    name: 'Buidler (Hardhat) Early Adopter',
    image: 'https://storage.googleapis.com/poapmedia/buidler-hardhat-early-adopter-2020-logo-1609363387688.png'
  },
  {
    name: 'Foxyoga Listening Party: The Matthew and Rizzle Show! Featuring: CoFounder and CEO: Roneil Rumburg of Audius Music',
    image: 'https://storage.googleapis.com/poapmedia/foxyoga-listening-party-the-matthew-and-rizzle-show-featuring-cofounder-and-ceo-roneil-rumburg-of-audius-music-2020-logo-1609359908617.png'
  },
  {
    name: 'Decentraland 2020 End of Year Party!',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-2020-end-of-year-party-2020-logo-1609259272363.png'
  },
  {
    name: 'Cryptograph Owners Club 2020',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-owners-club-2020-2020-logo-1609168116959.png'
  },
  {
    name: 'Decentraleo 2020 Genesis 🏆',
    image: 'https://storage.googleapis.com/poapmedia/dcl-2020-nft-2020-logo-1609209322889.png'
  },
  {
    name: 'Power Challenge',
    image: 'https://storage.googleapis.com/poapmedia/power-challenge-2020-logo-1609105792072.png'
  },
  {
    name: 'The Haiku Book',
    image: 'https://storage.googleapis.com/poapmedia/the-haiku-book-2020-logo-1609085995477.png'
  },
  {
    name: 'Beacon Chain Genesis Depositor',
    image: 'https://storage.googleapis.com/poapmedia/beacon-chain-genesis-depositor-2020-logo-1609033712464.png'
  },
  {
    name: 'DAppChaser GR8 Supporter',
    image: 'https://storage.googleapis.com/poapmedia/dappchaser-gr8-supporter-2020-logo-1609006018412.png'
  },
  {
    name: 'Etherum Lima Diciembre 2020',
    image: 'https://storage.googleapis.com/poapmedia/etherum-lima-diciembre-2020-2020-logo-1608950676251.png'
  },
  {name: 'poapEthLima0', image: 'https://storage.googleapis.com/poapmedia/poapethlima0-2020-logo-1608946199088.png'},
  {
    name: 'ECC Brownbag - The NFT Rabbithole',
    image: 'https://storage.googleapis.com/poapmedia/ecc-brownbag-the-nft-rabbithole-2020-logo-1609879390093.png'
  },
  {
    name: 'Ho-Ho Not-So-Silent SNOWFEST',
    image: 'https://storage.googleapis.com/poapmedia/ho-ho-not-so-silent-snowfest-2020-logo-1608725661872.png'
  },
  {
    name: 'Foxy Listening Party: The Matthew and Rizzle Show',
    image: 'https://storage.googleapis.com/poapmedia/foxy-listening-party-the-matthew-and-rizzle-show-2020-logo-1608725601367.png'
  },
  {
    name: 'Frankys Tavern - New Years, 2021',
    image: 'https://storage.googleapis.com/poapmedia/frankys-tavern-nye-2021-party-2020-logo-1609283489254.png'
  },
  {
    name: 'DABDRAGON HANGOUTS',
    image: 'https://storage.googleapis.com/poapmedia/dabdragon-hangouts-2020-logo-1608629377233.png'
  },
  {
    name: 'Known Origin: Live Drop Cryptospectr X-Spaced-Painter',
    image: 'https://storage.googleapis.com/poapmedia/known-origin-live-drop-cryptospectr-x-spaced-painter-2020-logo-1608629296921.png'
  },
  {
    name: 'WEEKLY AVASTAR GIVEAWAY',
    image: 'https://storage.googleapis.com/poapmedia/weekly-avastar-giveaway-2020-logo-1608629212779.png'
  },
  {
    name: 'Collectorshub Supporter',
    image: 'https://storage.googleapis.com/poapmedia/collectorshub-supporter-2020-logo-1608543478300.png'
  },
  {
    name: 'EZincrypto’s Listening Party',
    image: 'https://storage.googleapis.com/poapmedia/ezincryptos-listening-party-2020-logo-1608536229467.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 18)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-18-2020-logo-1608430572944.png'
  },
  {name: 'Dai Hard', image: 'https://storage.googleapis.com/poapmedia/dai-hard-2020-logo-1608324397120.png'},
  {
    name: 'BuenPodcast Navideño',
    image: 'https://storage.googleapis.com/poapmedia/buenpodcast-navideno-2020-logo-1608317196252.png'
  },
  {
    name: 'CoinGecko Christmas 2020 – SantaGecko',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-exclusive-christmas-nft-2020-logo-1608271331971.png'
  },
  {
    name: '‘Release the Kraken’ Xmas party with 3LAU Live!',
    image: 'https://storage.googleapis.com/poapmedia/release-the-kraken-xmas-party-with-3lau-live-2020-logo-1608257862213.png'
  },
  {
    name: 'Xmas Treasure Hunt - Jingle All the Way',
    image: 'https://storage.googleapis.com/poapmedia/xmas-treasure-hunt-jingle-all-the-way-2020-logo-1608257692264.png'
  },
  {
    name: 'FRANKYNEEDLES METAVERSE FLASHMOB',
    image: 'https://storage.googleapis.com/poapmedia/frankyneedles-metaverse-flashmob-2020-logo-1608248147594.png'
  },
  {
    name: 'ETHGDL: Hola Ethereum. Tu Primer Smart Contract',
    image: 'https://storage.googleapis.com/poapmedia/ethgdl-hola-ethereum-tu-primer-smart-contract-2020-logo-1608236726881.png'
  },
  {
    name: 'DeFi LATAM - Meetup #2',
    image: 'https://storage.googleapis.com/poapmedia/defi-latam-meetup-2-2020-logo-1608296794536.png'
  },
  {
    name: 'Who-What-Weearable W/ Lolaroses & Avocuddle 12.18.2020',
    image: 'https://storage.googleapis.com/poapmedia/who-what-weearable-w-lolaroses-avocuddle-12182020-2020-logo-1608168349139.png'
  },
  {
    name: 'BrightID Community Call & AMA # 2 - TheEther',
    image: 'https://storage.googleapis.com/poapmedia/brightid-community-call-ama-2-2020-logo-1609995972141.png'
  },
  {
    name: 'CryptoAvatars AMA #03',
    image: 'https://storage.googleapis.com/poapmedia/cryptoavatars-ama-03-2020-logo-1608138765294.png'
  },
  {
    name: 'THE VERY FIRST FOXYOGA LISTENING PARTY: THE MATTHEW AND RIZZLE SHOW',
    image: 'https://storage.googleapis.com/poapmedia/the-very-first-foxyoga-listening-party-the-matthew-and-rizzle-show-2020-logo-1608080010513.png'
  },
  {
    name: 'THE SECOND NFT DEV SALOON',
    image: 'https://storage.googleapis.com/poapmedia/the-second-nft-dev-saloon-2020-logo-1608078972991.png'
  },
  {
    name: 'TOP SECRET EVENT',
    image: 'https://storage.googleapis.com/poapmedia/top-secret-event-2020-logo-1608078918699.png'
  },
  {
    name: '#CryptoAtelier Launch',
    image: 'https://storage.googleapis.com/poapmedia/cryptoatelier-launch-2020-logo-1608070841647.png'
  },
  {
    name: 'EthereumBA 26th edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-26th-edition-2020-logo-1608043673388.gif'
  },
  {
    name: 'DABDRAGON HANGOUTS 12.15.2020',
    image: 'https://storage.googleapis.com/poapmedia/dabdragon-hangouts-12152020-2020-logo-1608009719542.png'
  },
  {
    name: 'Weekly AVASTARS Giveaway! 12.15.2020',
    image: 'https://storage.googleapis.com/poapmedia/weekly-avastars-giveaway-12152020-2020-logo-1607985448918.png'
  },
  {
    name: 'Genesis POAP Design Contest',
    image: 'https://storage.googleapis.com/poapmedia/genesis-poap-design-contest-2020-logo-1607918279446.png'
  },
  {
    name: 'Community Call #14 w/ David Rugendyke',
    image: 'https://storage.googleapis.com/poapmedia/community-call-14-w-david-rugendyke-2020-logo-1607914168420.png'
  },
  {
    name: 'Community Call #13 w/ Sacha Saint-Leger',
    image: 'https://storage.googleapis.com/poapmedia/community-call-13-w-sacha-saint-leger-2020-logo-1607914065872.png'
  },
  {
    name: 'HAPPY BIRTHDAY RIZZLE- SURPRISE PARTY 2020',
    image: 'https://storage.googleapis.com/poapmedia/happy-birthday-rizzle-surprise-party-2020-2020-logo-1607893883954.png'
  },
  {
    name: 'BrightID Community Call & AMA # 1 - 1Hive',
    image: 'https://storage.googleapis.com/poapmedia/brightid-community-call-ama-1-2020-logo-1607613133646.png'
  },
  {
    name: 'CryptoAvatars AMA #02',
    image: 'https://storage.googleapis.com/poapmedia/cryptoavatars-ama-02-2020-logo-1607595356116.png'
  },
  {
    name: 'FLUX 12.11.2020 by Metageist',
    image: 'https://storage.googleapis.com/poapmedia/flux-12112020-by-metageist-2020-logo-1607676453157.png'
  },
  {
    name: 'The Metalympics: BattleRacers /Sponsored by: Terra Virtua',
    image: 'https://storage.googleapis.com/poapmedia/the-metalympics-battleracers-sponsored-by-terra-virtua-2020-logo-1607581122493.png'
  },
  {
    name: 'Ethereum TGU Edition #3',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-tgu-edition-3-2020-logo-1607569282983.png'
  },
  {
    name: 'Community Call #12 w/ Lakshman Sankar',
    image: 'https://storage.googleapis.com/poapmedia/community-call-12-w-lakshman-sangar-2020-logo-1607624479732.png'
  },
  {
    name: 'Sandymeows Live Listening Party: Matthew & Rizzle Show featuring Mark Rendar from Rendar.org',
    image: 'https://storage.googleapis.com/poapmedia/sandymeows-live-listening-party-matthew-rizzle-show-featuring-mark-rendar-from-rendarorg-2020-logo-1607543855664.png'
  },
  {
    name: 'Caribe.ETH Vol.4 - Jardines, Autómatas y Dragones',
    image: 'https://storage.googleapis.com/poapmedia/caribeeth-vol4-jardines-automatas-y-dragones-2020-logo-1607541565758.png'
  },
  {
    name: 'Tim Roughgarden: An Economic Analysis of EIP-1559 + Q&A with Vitalik Buterin',
    image: 'https://storage.googleapis.com/poapmedia/tim-roughgarden-an-economic-analysis-of-eip-1559-qa-with-vitalik-buterin-2020-logo-1608393082409.png'
  },
  {
    name: 'GoodGhosting Alpha Testers',
    image: 'https://storage.googleapis.com/poapmedia/goodghosting-alpha-testers-2020-logo-1607420418901.png'
  },
  {
    name: 'DABDRAGON HANGOUTS 12.08.2020',
    image: 'https://storage.googleapis.com/poapmedia/dabdragon-hangouts-12082020-2020-logo-1607374018775.png'
  },
  {
    name: 'Weekly AVASTARS Giveaway! 12.08.2020',
    image: 'https://storage.googleapis.com/poapmedia/weekly-avastars-giveaway-12072020-2020-logo-1607373941303.png'
  },
  {
    name: 'Ezincryptos Listening Party: TokenSmart Podcast Special With Sandymeows 12/7/2020',
    image: 'https://storage.googleapis.com/poapmedia/ezincryptos-listening-party-tokensmart-podcast-special-with-sandymeows-1272020-2020-logo-1607373861222.png'
  },
  {
    name: 'Chicago Ethereum Meetup - Yield Protocol with Allan Niemerg',
    image: 'https://storage.googleapis.com/poapmedia/chicago-ethereum-meetup-yield-protocol-with-allan-niemerg-2020-logo-1607369652959.png'
  },
  {
    name: 'Clr.fund Contributor (Round 1)',
    image: 'https://storage.googleapis.com/poapmedia/auryn-macmillan-2020-logo-1607347428575.png'
  },
  {
    name: 'KEEP Network Mainnet Stakers',
    image: 'https://storage.googleapis.com/poapmedia/keep-network-mainnet-stakers-2020-logo-1607295999088.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 17)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-17-2020-logo-1607263586260.png'
  },
  {
    name: 'Decentraland Navigator Crypto Cinema Opening Medal',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-navigator-crypto-cinema-opening-medal-2020-logo-1607175141658.png'
  },
  {
    name: 'ETH Staker Community Quiz Show Winners - 2020',
    image: 'https://storage.googleapis.com/poapmedia/eth-staker-community-quiz-show-winner-2020-logo-1607113078787.png'
  },
  {
    name: 'Fight Pandemics Badges',
    image: 'https://storage.googleapis.com/poapmedia/fight-pandemics-badges-2020-logo-1607085898622.png'
  },
  {
    name: 'SSX3LAU - Makersplace @ Decentraland',
    image: 'https://storage.googleapis.com/poapmedia/ssx3lau-makersplace-decentraland-2020-logo-1607005452175.png'
  },
  {
    name: 'First CryptoAvatars AMA',
    image: 'https://storage.googleapis.com/poapmedia/first-cryptoavatars-ama-2020-logo-1606986242790.png'
  },
  {
    name: 'DIGITALAX TOKEN RELEASE & NFT STAKING 12/4/2020',
    image: 'https://storage.googleapis.com/poapmedia/digitalax-token-release-nft-staking-1242020-2020-logo-1606981885807.png'
  },
  {
    name: 'Who-What-Weearable W/ Lolaroses & Avocuddle 12/4/2020',
    image: 'https://storage.googleapis.com/poapmedia/who-what-weearable-w-lolaroses-avocuddle-1242020-2020-logo-1606981771007.png'
  },
  {
    name: 'The Metalympics 12/3/2020',
    image: 'https://storage.googleapis.com/poapmedia/the-metalympics-1232020-2020-logo-1606981686261.png'
  },
  {
    name: 'Aave V2 Pionners NFT',
    image: 'https://storage.googleapis.com/poapmedia/aave-v2-pionners-nft-2020-logo-1606988227748.png'
  },
  {
    name: 'Dutchtide X HarrisonFirst auction',
    image: 'https://storage.googleapis.com/poapmedia/dutchtide-x-harrisonfirst-auction-2020-logo-1606909164500.png'
  },
  {
    name: 'The Matthew and Rizzle Show- Featuring PowerSurge',
    image: 'https://storage.googleapis.com/poapmedia/the-matthew-and-rizzle-show-featuring-powersurge-2020-logo-1606873706130.png'
  },
  {
    name: 'NFT DEV SALOON 2020',
    image: 'https://storage.googleapis.com/poapmedia/nft-dev-saloon-2020-2020-logo-1606872991282.png'
  },
  {
    name: '$COKE: The Culture of Axie Infinity',
    image: 'https://storage.googleapis.com/poapmedia/coke-the-culture-of-axie-infinity-2020-logo-1606872872103.png'
  },
  {
    name: 'Ethereum Bogotá - Diciembre 2020',
    image: 'https://storage.googleapis.com/poapmedia/como-desarrollar-un-contrato-inteligente-usando-test-driven-development-2020-logo-1606862697797.png'
  },
  {
    name: 'Blockchain + Dig. Currency Academia',
    image: 'https://storage.googleapis.com/poapmedia/blockchain-dig-currency-academia-2020-logo-1606856458861.png'
  },
  {
    name: 'DABDRAGON HANGOUTS 12.01.2020',
    image: 'https://storage.googleapis.com/poapmedia/dabdragon-hangouts-12012020-2020-logo-1606794632325.png'
  },
  {
    name: 'Weekly AVASTARS Giveaway! 12.01.2020',
    image: 'https://storage.googleapis.com/poapmedia/weekly-avastars-giveaway-12012020-2020-logo-1606793997362.png'
  },
  {
    name: 'WIP ASIA Sponsored by KnownOrigin ',
    image: 'https://storage.googleapis.com/poapmedia/wip-asia-sponsored-by-knownorigin-2020-logo-1606773190875.png'
  },
  {
    name: 'Ezincryptos Listening Party: TokenSmart Podcast',
    image: 'https://storage.googleapis.com/poapmedia/ezincryptos-listening-party-tokensmart-podcast-2020-logo-1606769006129.png'
  },
  {
    name: 'Uniswap Tutorial',
    image: 'https://storage.googleapis.com/poapmedia/juan-alberto-mayen-maldonado-2020-logo-1606616459197.png'
  },
  {
    name: 'Ethereum 2.0 Serenity Launch',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-20-serenity-launch-2020-logo-1606540348892.png'
  },
  {
    name: 'False Start Lumberjackers',
    image: 'https://storage.googleapis.com/poapmedia/false-start-lumberjackers-2020-logo-1606514369388.png'
  },
  {name: 'DYGYCON 2020', image: 'https://storage.googleapis.com/poapmedia/dygycon-2020-2020-logo-1606451355021.png'},
  {
    name: 'II Foro de Crypto Economía',
    image: 'https://storage.googleapis.com/poapmedia/ii-foro-de-crypto-economia-2020-logo-1606385487138.png'
  },
  {
    name: 'MCV Conclave of the Mages 2020',
    image: 'https://storage.googleapis.com/poapmedia/mcv-conclave-of-the-mages-2020-2020-logo-1607700754913.png'
  },
  {
    name: 'THE MIP: The Amazon Concert by Jose Cabrera',
    image: 'https://storage.googleapis.com/poapmedia/the-mip-the-amazon-concert-by-jose-cabrera-2020-logo-1606282125488.png'
  },
  {
    name: '2020 GOBBLE GOBBLE FRIENDSGIVING METAFESTIVAL Presented by TokenSmart & Bittrees',
    image: 'https://storage.googleapis.com/poapmedia/2020-gobble-gobble-friendsgiving-metafestival-presented-by-tokensmart-bittrees-2020-logo-1606281881510.png'
  },
  {
    name: 'Studio Nouveau "Audiovisual" Streaming Release Party!',
    image: 'https://storage.googleapis.com/poapmedia/studio-nouveau-audiovisual-streaming-release-party-2020-logo-1606246362096.png'
  },
  {
    name: 'DIGITALAX LAUNCH 11.24.2020',
    image: 'https://storage.googleapis.com/poapmedia/digitalax-launch-11242020-2020-logo-1606236960449.png'
  },
  {
    name: 'DABDRAGON HANGOUTS 11.24.2020',
    image: 'https://storage.googleapis.com/poapmedia/dabdragon-hangouts-11242020-2020-logo-1606236840429.png'
  },
  {
    name: 'Weekly AVASTARS Giveaway! 11.24.2020',
    image: 'https://storage.googleapis.com/poapmedia/weekly-avastars-giveaway-11242020-2020-logo-1606236742402.png'
  },
  {
    name: 'DeFi LATAM - Meetup #1',
    image: 'https://storage.googleapis.com/poapmedia/defi-latam-meetup-1-2020-logo-1606235390608.png'
  },
  {
    name: 'EthereumBA 25th edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-25th-edition-2020-logo-1606089924737.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 16)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-16-2020-logo-1606016116174.png'
  },
  {name: 'qryptic ', image: 'https://storage.googleapis.com/poapmedia/qryptic-2020-logo-1605935138312.png'},
  {
    name: 'DeFi Institute',
    image: 'https://storage.googleapis.com/poapmedia/defi-institute-2020-logo-1605918651830.png'
  },
  {
    name: 'Who-What-Weearable W/ Lolaroses & Avocuddle 11.20.2020',
    image: 'https://storage.googleapis.com/poapmedia/who-what-weearable-w-lolaroses-avocuddle-11202020-2020-logo-1605833921746.png'
  },
  {
    name: 'FLUX 11.20.2020',
    image: 'https://storage.googleapis.com/poapmedia/flux-11202020-2020-logo-1605832246240.png'
  },
  {
    name: 'Aave x Coingecko V2 Release Swag Event',
    image: 'https://storage.googleapis.com/poapmedia/aave-x-coingecko-v2-release-swag-event-2020-logo-1606990187877.png'
  },
  {
    name: 'The Metalympics 11/19/2020',
    image: 'https://storage.googleapis.com/poapmedia/the-metalympics-11192020-2020-logo-1605805257953.png'
  },
  {
    name: 'The WIP Meetup 11/19/2020',
    image: 'https://storage.googleapis.com/poapmedia/the-wip-meetup-11192020-2020-logo-1605804846985.png'
  },
  {
    name: 'ETH Staker Validator Workshop',
    image: 'https://storage.googleapis.com/poapmedia/eth-staker-validator-workshop-2020-logo-1605797750104.png'
  },
  {
    name: 'Sandymeow’s Listening Party: The Matthew & Rizzle Show ',
    image: 'https://storage.googleapis.com/poapmedia/sandymeows-listening-party-the-matthew-rizzle-show-2020-logo-1605742947890.png'
  },
  {
    name: 'Chainlink Builder: Acknowledge discord builders',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-acknowledge-discord-builders-2020-logo-1605722707100.png'
  },
  {
    name: 'Chainlink Builder: Built a new External Adaptor',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-built-a-new-external-adaptor-2020-logo-1605722572876.png'
  },
  {
    name: 'Chainlink Builder: Answer questions on Stack Overflow',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-answer-questions-on-stack-overflow-2020-logo-1605722473116.png'
  },
  {
    name: 'Chainlink Builder: Answer questions on Discord',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-answer-questions-on-discord-2020-logo-1605722333284.png'
  },
  {
    name: 'Chainlink Builder: Built a smart contract with Chainlink on mainnet',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-built-a-smart-contract-with-chainlink-on-mainnet-2020-logo-1605722249697.png'
  },
  {
    name: 'Chainlink Builder: Smart contract with VRF on mainnet',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-smart-contract-with-vrf-on-mainnet-2020-logo-1605722150909.png'
  },
  {
    name: 'Chainlink Builder: I have a smart contract with Chainlink on testnet',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-i-have-a-smart-contract-with-chainlink-on-testnet-2020-logo-1605721881885.png'
  },
  {
    name: 'Chainlink Builder: I wrote a technical blog post',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-builder-i-wrote-a-technical-blog-post-2020-logo-1605721777027.png'
  },
  {
    name: 'Builder at the Chainlink Hackathon ',
    image: 'https://storage.googleapis.com/poapmedia/builder-at-the-chainlink-hackathon-2020-logo-1605721354437.png'
  },
  {
    name: 'EthereumBA Asado -Octubre 2020-',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-asado-octubre-2020-2020-logo-1605560259144.png'
  },
  {
    name: 'EthStaker Community Call #11: Deposit Launchpad Roundtable',
    image: 'https://storage.googleapis.com/poapmedia/ethstaker-community-call-11-deposit-launchpad-roundtable-2020-logo-1605543750961.png'
  },
  {
    name: '2.5 The Talk Show',
    image: 'https://storage.googleapis.com/poapmedia/25-the-talk-show-2020-logo-1605540900946.png'
  },
  {
    name: 'stakefish eth2 Early Adopter',
    image: 'https://storage.googleapis.com/poapmedia/stakefish-eth2-early-adopter-2020-logo-1605524464141.png'
  },
  {name: 'Test POAP ABC', image: 'https://storage.googleapis.com/poapmedia/test-poap-abc-2020-logo-1605523276337.png'},
  {
    name: 'Nature 2.0 finalist at Odyssey 2020',
    image: 'https://storage.googleapis.com/poapmedia/nature-20-finalist-at-odyssey-2020-2020-logo-1605519789328.png'
  },
  {
    name: 'MakerDAO Contributor - Q3',
    image: 'https://storage.googleapis.com/poapmedia/makerdao-contributor-q3-2020-logo-1605302232170.png'
  },
  {
    name: 'BanklessFR 2020',
    image: 'https://storage.googleapis.com/poapmedia/banklessfr-2020-2020-logo-1605301134714.png'
  },
  {
    name: 'BarterTrade Female',
    image: 'https://storage.googleapis.com/poapmedia/bartertrade-female-2020-logo-1605262485957.png'
  },
  {
    name: 'ETHOnline Attendee',
    image: 'https://storage.googleapis.com/poapmedia/ethonline-attendee-2020-logo-1605221190961.png'
  },
  {
    name: 'ETHOnline Hacker',
    image: 'https://storage.googleapis.com/poapmedia/ethonline-hacker-2020-logo-1605210556097.png'
  },
  {
    name: 'Project Management Initiation',
    image: 'https://storage.googleapis.com/poapmedia/project-management-initiation-2020-logo-1605191188812.png'
  },
  {
    name: 'Crash Crew, November 2020',
    image: 'https://storage.googleapis.com/poapmedia/crash-crew-november-2020-2020-logo-1605133157256.png'
  },
  {
    name: 'Ethereum TGU Edition #2',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-tgu-edition-2-2020-logo-1605107984585.png'
  },
  {
    name: 'Clr.fund Genesis Contributor (Round 0)',
    image: 'https://storage.googleapis.com/poapmedia/clrfund-genesis-contributor-round-0-2020-logo-1605025182463.png'
  },
  {
    name: 'Bronze Bitcoin Bull Auction Day',
    image: 'https://storage.googleapis.com/poapmedia/bronze-bitcoin-bull-auction-day-2020-logo-1604957048223.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 17 con Juandavid Reyes',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-17-con-juandavid-reyes-2020-logo-1604945340074.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 16 con Raul Jordan y Justin Drake',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-16-con-raul-jordan-y-justin-drake-2020-logo-1604945289081.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 14 con Pablo Candela',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-14-con-pablo-candela-2020-logo-1604945226627.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 14 con Nadia Alvarez',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-14-con-nadia-alvarez-2020-logo-1604945150229.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 13 con Maria Gómez',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-13-con-maria-gomez-2020-logo-1604945100377.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 12 con Andrés Junge y Milton Berman',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-12-con-andres-junge-y-milton-berman-2020-logo-1604945024677.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 11 con Diego Mazo',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-11-con-diego-mazo-2020-logo-1604944972895.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 10 con Alejandro Beltrán y Federico Ast',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-10-con-alejandro-beltran-y-federico-ast-2020-logo-1604944933409.png'
  },
  {
    name: 'Solidity Underhanded Contest 2020 - Winners',
    image: 'https://storage.googleapis.com/poapmedia/solidity-underhanded-contest-2020-winners-2020-logo-1604937031889.png'
  },
  {
    name: 'FriDAI brunch #6',
    image: 'https://storage.googleapis.com/poapmedia/fridai-brunch-6-2020-logo-1604914329425.png'
  },
  {name: 'FMTLOL', image: 'https://storage.googleapis.com/poapmedia/fmtlol-2020-logo-1604846183106.png'},
  {
    name: 'ETH Network - Attendee (Gen 15)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-15-2020-logo-1604796701121.png'
  },
  {
    name: 'Meetup de Ethereum Bogotá con Legal Hackers sobre DAOs',
    image: 'https://storage.googleapis.com/poapmedia/meetup-de-ethereum-bogota-con-legal-hackers-sobre-daos-2020-logo-1604770564731.png'
  },
  {name: 'KICKOFF x LBL ', image: 'https://storage.googleapis.com/poapmedia/kickoff-x-lbl-2020-logo-1604686452095.png'},
  {
    name: 'A New Face For yCover',
    image: 'https://storage.googleapis.com/poapmedia/a-new-face-for-ycover-2020-logo-1604601079479.png'
  },
  {
    name: 'Pop concert test event',
    image: 'https://storage.googleapis.com/poapmedia/pop-concert-test-event-2020-logo-1604587791616.png'
  },
  {
    name: 'Medalla Data Challenge Submitter',
    image: 'https://storage.googleapis.com/poapmedia/medalla-data-challenge-submitter-2020-logo-1604514202225.png'
  },
  {
    name: 'Ethereum 2.0 Deposit Contract Launch',
    image: 'https://storage.googleapis.com/poapmedia/poap-test-2020-logo-1604505191693.png'
  },
  {
    name: 'DeFi Education Sessions 2020',
    image: 'https://storage.googleapis.com/poapmedia/defi-education-sessions-2020-2020-logo-1604481140622.png'
  },
  {
    name: 'BitGo family member 2020',
    image: 'https://storage.googleapis.com/poapmedia/bitgo-2020-placeholder-2020-logo-1606087050180.png'
  },
  {
    name: 'Test Event 007',
    image: 'https://storage.googleapis.com/poapmedia/test-event-007-2020-logo-1604353776062.png'
  },
  {
    name: 'Odyssey Momentum - Conscious Cities - Scheveningen Living Lab 3.0 STORK gold',
    image: 'https://storage.googleapis.com/poapmedia/odyssey-momentum-conscious-cities-scheveningen-living-lab-30-stork-gold-2020-logo-1604318274480.png'
  },
  {
    name: 'Odyssey Momentum - Conscious Cities - Scheveningen Living Lab 3.0 STORK silver',
    image: 'https://storage.googleapis.com/poapmedia/odyssey-momentum-conscious-cities-scheveningen-living-lab-30-stork-silver-2020-logo-1604318202166.png'
  },
  {
    name: 'Odyssey Momentum - Conscious Cities - Scheveningen Living Lab 3.0 STORK bronze',
    image: 'https://storage.googleapis.com/poapmedia/odyssey-momentum-conscious-cities-scheveningen-living-lab-30-stork-bronze-2020-logo-1604318121894.png'
  },
  {
    name: 'ETH Staker Community Quiz Show - Viewer',
    image: 'https://storage.googleapis.com/poapmedia/eth-staker-community-quiz-show-viewer-2020-logo-1604257968457.png'
  },
  {
    name: 'ETH Staker Community Quiz Show - Participant',
    image: 'https://storage.googleapis.com/poapmedia/eth-staker-community-quiz-show-participant-2020-logo-1604257900864.png'
  },
  {
    name: 'I Played 4 YFI',
    image: 'https://storage.googleapis.com/poapmedia/i-played-4-yfi-2020-logo-1604247754471.png'
  },
  {
    name: 'CoinGecko Yield Farmer - Farmed them all!',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-yield-farmer-gotta-farm-them-all-2020-logo-1604416385604.png'
  },
  {
    name: 'CoinGecko SUSHI Yield Farmer ',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-sushi-yield-farmer-2020-logo-1604416413936.png'
  },
  {
    name: 'CoinGecko Halloween 2020 - Joker Gecko',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-halloween-2020-joker-gecko-2020-logo-1604209753463.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 9 con Jose Bell',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-9-con-jose-bell-2020-logo-1603989370276.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 8 con Alan Becker',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-8-con-alan-becker-2020-logo-1603989322579.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 7 con Santiago Palladino',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-7-con-santiago-palladino-2020-logo-1603989238234.png'
  },
  {
    name: 'BarterTrade Mainnet Platinum',
    image: 'https://storage.googleapis.com/poapmedia/bartertrade-mainnet-platinum-2020-logo-1603978078664.png'
  },
  {
    name: 'BarterTrade Mainnet Gold',
    image: 'https://storage.googleapis.com/poapmedia/bartertrade-mainnet-gold-2020-logo-1603978019351.png'
  },
  {
    name: 'BarterTrade Mainnet Bronze',
    image: 'https://storage.googleapis.com/poapmedia/bartertrade-mainnet-bronze-2020-logo-1603977948104.png'
  },
  {
    name: 'ETH Staker Community Call #10: Secret shared validators and trustless staking pools',
    image: 'https://storage.googleapis.com/poapmedia/eth-staker-community-call-10-secret-shared-validators-and-trustless-staking-pools-2020-logo-1603976971806.png'
  },
  {
    name: 'PoolTogether Winner',
    image: 'https://storage.googleapis.com/poapmedia/pooltogether-v3-winner-2020-logo-1603904029079.png'
  },
  {
    name: 'Chainlink Hackathon Sponsorship',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-hackathon-sponsorship-2020-logo-1603850515301.png'
  },
  {
    name: 'Ethereum Bogotá OG Colegas',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-bogota-og-colegas-2020-logo-1603837640446.png'
  },
  {
    name: 'Chicago Ethereum Meetup - NFTs with Andrew Steinwold and Kinchasa',
    image: 'https://storage.googleapis.com/poapmedia/spencer-graham-2020-logo-1603819546371.png'
  },
  {name: 'I Voted', image: 'https://storage.googleapis.com/poapmedia/i-voted-govrn-test-2020-logo-1603771026173.png'},
  {
    name: 'ETH Staker Community Quiz Show',
    image: 'https://storage.googleapis.com/poapmedia/eth-staker-community-quiz-show-2020-logo-1603746694495.png'
  },
  {
    name: 'Decentraland Halloween 2020 - Party!',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-halloween-2020-party-2020-logo-1603746599388.png'
  },
  {
    name: 'Decentraland Halloween 2020 - Final Huntdown',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-halloween-2020-final-huntdown-2020-logo-1603746478529.png'
  },
  {
    name: 'Decentraland Halloween 2020 - Pumpkin Problem',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-halloween-2020-pumpkin-problem-2020-logo-1603746356311.png'
  },
  {
    name: 'Decentraland Halloween 2020 - Temple Trouble',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-halloween-2020-temple-trouble-2020-logo-1603746177706.png'
  },
  {
    name: 'Decentraland Halloween 2020 - Ghosts n Graves',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-halloween-2020-ghosts-n-graves-2020-logo-1603746093276.png'
  },
  {
    name: 'Decentraland Halloween 2020 - Trick or Treat',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-halloween-2020-trick-or-treat-2020-logo-1603745964713.png'
  },
  {
    name: 'EthereumBA 24th edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-24th-edition-2020-logo-1603657967222.png'
  },
  {name: 'RMK - POAP0', image: 'https://storage.googleapis.com/poapmedia/rmk-poap0-2020-logo-1603657172093.png'},
  {
    name: 'ETH Network - Attendee (Gen 14)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-14-2020-logo-1603636786482.png'
  },
  {
    name: 'ETH Online |  Intro to ETH2 & Staking for Beginners',
    image: 'https://storage.googleapis.com/poapmedia/eth-online-intro-to-eth2-staking-for-beginners-2020-logo-1603384429688.png'
  },
  {
    name: 'I Voted - Badge1',
    image: 'https://storage.googleapis.com/poapmedia/i-voted-badge1-2020-logo-1603348877723.png'
  },
  {name: 'Test I Voted', image: 'https://storage.googleapis.com/poapmedia/test-i-voted-2020-logo-1603236200522.png'},
  {
    name: 'Chainlink test',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-test-2020-logo-1603142029316.png'
  },
  {
    name: 'Test Event 123',
    image: 'https://storage.googleapis.com/poapmedia/test-event-123-2020-logo-1603078325726.png'
  },
  {
    name: 'All Things Blockchain Member - 2020',
    image: 'https://storage.googleapis.com/poapmedia/all-things-blockchain-member-2020-2020-logo-1602990754331.png'
  },
  {
    name: 'testtestestestse',
    image: 'https://storage.googleapis.com/poapmedia/testtestestestse-2020-logo-1602953532384.png'
  },
  {
    name: 'NFT GOLDEN POAP',
    image: 'https://storage.googleapis.com/poapmedia/nft-golden-poap-2020-logo-1602906071042.png'
  },
  {
    name: 'DeFi Madrid Inauguración',
    image: 'https://storage.googleapis.com/poapmedia/defi-madrid-inauguracion-2020-logo-1602770110791.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 6 con Ángel Rendón',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-6-con-angel-rendon-2020-logo-1602696702873.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 5 con Mauricio Tovar',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-5-con-mauricio-tovar-2020-logo-1602696660534.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 4 con Cristobal Pereira',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-4-con-cristobal-pereira-2020-logo-1602696416612.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 3 con Camila Russo',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-3-con-camila-russo-2020-logo-1602696369510.png'
  },
  {
    name: 'Chainlink Flux Monitor: Shipped It! ',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-flux-monitor-shipped-it-2020-logo-1602675411933.png'
  },
  {
    name: 'Chainlink Flux Monitor: I shipped it! ',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-flux-monitor-i-shipped-it-2020-logo-1602669732082.png'
  },
  {
    name: 'Introducción de Algoritmos Criptográficos en la Cadena de Bloques',
    image: 'https://storage.googleapis.com/poapmedia/introduccion-de-algoritmos-criptograficos-en-la-cadena-de-bloques-2020-logo-1603772620801.png'
  },
  {
    name: 'Descubriendo Blockchain - Sesión 2 con Alejandro Machado y Evan Van Ness',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-sesion-2-con-alejandro-machado-y-evan-van-ness-2020-logo-1602629265098.png'
  },
  {
    name: 'Chainlink Flux Monitor Launch Party Test',
    image: 'https://storage.googleapis.com/poapmedia/chainlink-flux-monitor-launch-party-test-2020-logo-1602613435090.png'
  },
  {
    name: 'Escalabilidad en Ethereum Hoy: zkRollups',
    image: 'https://storage.googleapis.com/poapmedia/escalabilidad-en-ethereum-hoy-zkrollups-2020-logo-1602654168435.png'
  },
  {
    name: 'Solidity Underhanded Contest 2020 ',
    image: 'https://storage.googleapis.com/poapmedia/solidity-underhanded-contest-2020-2020-logo-1604935052324.png'
  },
  {
    name: 'Fashion Show 2.0 @ Decentraland',
    image: 'https://storage.googleapis.com/poapmedia/fashion-show-20-decentraland-2020-logo-1602524660664.png'
  },
  {
    name: 'Rarible Launch Party @ Decentraland',
    image: 'https://storage.googleapis.com/poapmedia/rarible-launch-party-decentraland-2020-logo-1602524526903.png'
  },
  {
    name: '$MEME Building Launch @ Decentraland',
    image: 'https://storage.googleapis.com/poapmedia/meme-building-launch-decentraland-2020-logo-1602524350724.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 13)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-13-2020-logo-1602425502641.png'
  },
  {
    name: 'CR Academia con Cointelegraph',
    image: 'https://storage.googleapis.com/poapmedia/cr-academia-con-cointelegraph-2020-logo-1602294606520.png'
  },
  {
    name: 'FriDAI brunch #5',
    image: 'https://storage.googleapis.com/poapmedia/fridai-brunch-5-2020-logo-1602248647648.png'
  },
  {
    name: 'ETH2 Zinken Testnet Launch Viewing Party',
    image: 'https://storage.googleapis.com/poapmedia/eth2-zinken-testnet-launch-viewing-party-2020-logo-1602480631688.png'
  },
  {
    name: 'TE Academy - Ecosystem Value Flows TMG1 - Oct2020',
    image: 'https://storage.googleapis.com/poapmedia/te-academy-ecosystem-value-flows-tmg1-oct2020-2020-logo-1602157913569.png'
  },
  {
    name: 'TE Academy - Introduction to Token Engineering - Oct2020',
    image: 'https://storage.googleapis.com/poapmedia/te-academy-introduction-to-token-engineering-oct2020-2020-logo-1602157698880.png'
  },
  {
    name: 'Ethereum TGU Edition #1',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-tgu-edition-1-2020-logo-1602131862824.png'
  },
  {
    name: 'Descubriendo blockchain',
    image: 'https://storage.googleapis.com/poapmedia/descubriendo-blockchain-2020-logo-1602015628856.png'
  },
  {name: '#HalfRekt', image: 'https://storage.googleapis.com/poapmedia/halfrekt-2020-logo-1601513643094.png'},
  {
    name: 'CoinGecko YFI Yield Farmer',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-yfi-yield-farmer-2020-logo-1602423042151.png'
  },
  {
    name: 'CoinGecko YAM Yield Farmer',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-yam-yield-farmer-2020-logo-1602423064868.png'
  },
  {
    name: 'CoinGecko COMP Yield Farmer',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-comp-yield-farmer-2020-logo-1602423072997.png'
  },
  {
    name: 'CoinGecko YFII Yield Farmer',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-yfii-yield-farmer-2020-logo-1601453176553.png'
  },
  {
    name: 'CoinGecko BAL Yield Farmer',
    image: 'https://storage.googleapis.com/poapmedia/coingecko-bal-yield-farmer-2020-logo-1602423049449.png'
  },
  {
    name: 'Rocket Pool Medalla Beta 2020 - MVP',
    image: 'https://storage.googleapis.com/poapmedia/rocket-pool-medalla-beta-2020-mvp-2020-logo-1601427928485.png'
  },
  {
    name: 'Rocket Pool Medalla Beta 2020 - Node Operator',
    image: 'https://storage.googleapis.com/poapmedia/rocket-pool-medalla-beta-2020-node-operator-2020-logo-1601427802347.png'
  },
  {
    name: 'Rocket Pool Medalla Beta 2020 - Staker',
    image: 'https://storage.googleapis.com/poapmedia/rocket-pool-medalla-beta-2020-staker-2020-logo-1601427644226.png'
  },
  {
    name: 'ETH2 Spadina Testnet Launch Viewing Party',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-stakers-community-call-10-spadina-testnet-launch-2020-logo-1601304699193.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 12)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-12-2020-logo-1601217969915.png'
  },
  {
    name: 'BarterTradeTest',
    image: 'https://storage.googleapis.com/poapmedia/bartertradetest-2020-logo-1601183103681.png'
  },
  {
    name: 'Official Unvetica BETA Tester Program',
    image: 'https://storage.googleapis.com/poapmedia/official-unvetica-beta-tester-program-2020-logo-1600986452950.png'
  },
  {
    name: 'Aokigahara  - Sea of trees',
    image: 'https://storage.googleapis.com/poapmedia/aokigahara-sea-of-trees-2020-logo-1600903925547.png'
  },
  {
    name: 'EthereumBA 23rd edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-23rd-edition-2020-logo-1600899557372.png'
  },
  {
    name: 'TruffleCon 2020',
    image: 'https://storage.googleapis.com/poapmedia/trufflecon-2020-2020-logo-1601677293175.png'
  },
  {
    name: 'Decentraland2020第一届领航猿加密中秋节',
    image: 'https://storage.googleapis.com/poapmedia/decentraland2020di-yi-jie-ling-hang-yuan-jia-mi-zhong-qiu-jie-2020-logo-1601536508281.png'
  },
  {
    name: 'BarterTrade Test',
    image: 'https://storage.googleapis.com/poapmedia/bartertrade-test-2020-logo-1600675358663.png'
  },
  {
    name: 'Charla abierta - Tokens en Ethereum: NFT Tokens + POAP Wallet',
    image: 'https://storage.googleapis.com/poapmedia/charla-abierta-tokens-en-ethereum-nft-tokens-poap-wallet-2020-logo-1600465526197.png'
  },
  {
    name: 'ETH2SCC Ethereum Stakers Community Call #9 : Protolamda',
    image: 'https://storage.googleapis.com/poapmedia/eth2scc-ethereum-stakers-community-call-9-protolamda-2020-logo-1600367677044.png'
  },
  {
    name: 'Ethereum Stakers Community Call #9: Protolamda',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-stakers-community-call-9-protolamda-2020-logo-1600367351063.png'
  },
  {
    name: 'Official POAP BETA Badge',
    image: 'https://storage.googleapis.com/poapmedia/official-poap-beta-badge-2020-logo-1600201274412.png'
  },
  {
    name: 'ETH2 Studymaster GENESIS',
    image: 'https://storage.googleapis.com/poapmedia/eth2-studymaster-genesis-2020-logo-1600123492932.png'
  },
  {
    name: 'ETH2 Studymaster',
    image: 'https://storage.googleapis.com/poapmedia/eth2-studymaster-2020-logo-1600123355469.png'
  },
  {
    name: 'Welcome to POAP',
    image: 'https://storage.googleapis.com/poapmedia/welcome-to-poap-2020-logo-1600123099143.png'
  },
  {
    name: 'Studio Nouveau "Audiovisual" Album Release Party!',
    image: 'https://storage.googleapis.com/poapmedia/studio-nouveau-audiovisual-album-release-party-2020-logo-1600094659579.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 11)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-11-2020-logo-1600007640670.png'
  },
  {name: 'Optino OG', image: 'https://storage.googleapis.com/poapmedia/optino-og-2020-logo-1599940149214.png'},
  {
    name: 'Ethereum TGU Edition #0',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-tgu-edition-0-2020-logo-1599927612553.png'
  },
  {name: 'namebase_test', image: 'https://storage.googleapis.com/poapmedia/namebasetest-2020-logo-1599922577847.png'},
  {
    name: 'Friend of POAP',
    image: 'https://storage.googleapis.com/poapmedia/official-poap-core-contributor-2020-logo-1599768036143.png'
  },
  {
    name: 'Official POAP Core Contributor ',
    image: 'https://storage.googleapis.com/poapmedia/official-poap-core-contributor-2020-logo-1599768036143.png'
  },
  {name: 'P O A P', image: 'https://storage.googleapis.com/poapmedia/p-o-a-p-2020-logo-1599724556566.png'},
  {
    name: 'Ethereum Stakers Community Call #8',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-stakers-community-call-8-2020-logo-1599710663746.png'
  },
  {
    name: 'Syscoin @ Bitconf 2020',
    image: 'https://storage.googleapis.com/poapmedia/syscoin-bitconf-2020-2020-logo-1599696475473.png'
  },
  {
    name: 'Quan @ Bitconf 2020',
    image: 'https://storage.googleapis.com/poapmedia/quan-bitconf-2020-2020-logo-1599609280562.png'
  },
  {
    name: 'Crash Crew, September 2020',
    image: 'https://storage.googleapis.com/poapmedia/crash-crew-september-2020-2020-logo-1599149806163.png'
  },
  {
    name: 'Proof of Gucci design competition',
    image: 'https://storage.googleapis.com/poapmedia/proof-of-gucci-design-competition-2020-logo-1599058118153.png'
  },
  {
    name: 'Proof of Gucci',
    image: 'https://storage.googleapis.com/poapmedia/proof-of-gucci-2020-logo-1599058054046.png'
  },
  {
    name: 'Youth Expo 2020',
    image: 'https://storage.googleapis.com/poapmedia/elena-sinelnikova-2020-logo-1598975075397.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 10)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-gen-10-2020-logo-1598799706639.png'
  },
  {name: 'Goose Test', image: 'https://storage.googleapis.com/poapmedia/goose-test-2020-logo-1598681455179.png'},
  {name: 'infer3', image: 'https://storage.googleapis.com/poapmedia/infer3-2020-logo-1598652285272.png'},
  {name: 'asdf', image: 'https://storage.googleapis.com/poapmedia/asdf-2020-logo-1598650343187.png'},
  {name: 'infer', image: 'https://storage.googleapis.com/poapmedia/infer-2020-logo-1598649671433.png'},
  {name: 'Test Test', image: 'https://storage.googleapis.com/poapmedia/test-test-2020-logo-1598649551146.png'},
  {
    name: 'UNVETICA POAP TESTING SESSION',
    image: 'https://storage.googleapis.com/poapmedia/unvetica-poap-testing-session-2020-logo-1598649338457.png'
  },
  {name: 'test123', image: 'https://storage.googleapis.com/poapmedia/test123-2020-logo-1598649064224.png'},
  {name: 'Lorem ipsum', image: 'https://storage.googleapis.com/poapmedia/lorem-ipsum-2020-logo-1598649002684.png'},
  {name: 'super infer', image: 'https://storage.googleapis.com/poapmedia/super-infer-2020-logo-1598648837902.png'},
  {
    name: 'FriDAI brunch #4',
    image: 'https://storage.googleapis.com/poapmedia/fridai-brunch-4-2020-logo-1598603465120.png'
  },
  {
    name: 'Crypto & Coffee #66',
    image: 'https://storage.googleapis.com/poapmedia/crypto-coffee-66-2020-logo-1598578665483.png'
  },
  {
    name: 'Chicago Ethereum Meetup - [ONLINE] Options for anything on Ethereum with Pods Finance',
    image: 'https://storage.googleapis.com/poapmedia/chicago-ethereum-meetup-2020-logo-1598484251002.png'
  },
  {
    name: 'EthereumBA 22nd edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-22nd-edition-2020-logo-1598215903859.png'
  },
  {name: 'Jumong', image: 'https://storage.googleapis.com/poapmedia/jumong-2020-logo-1598035324622.png'},
  {
    name: 'Medalla Testnet Resuscitator ',
    image: 'https://storage.googleapis.com/poapmedia/medalla-testnet-resuscitator-2020-logo-1598019050780.png'
  },
  {
    name: 'WIP Meetup 8/27',
    image: 'https://storage.googleapis.com/poapmedia/wip-meetup-827-2020-logo-1597946835498.png'
  },
  {
    name: 'Ethereal Sessions #004',
    image: 'https://storage.googleapis.com/poapmedia/ethereal-sessions-004-2020-logo-1597936567625.png'
  },
  {
    name: 'Ethereal Sessions #003',
    image: 'https://storage.googleapis.com/poapmedia/ethereal-sessions-003-2020-logo-1597936511469.png'
  },
  {name: 'YAM Heros', image: 'https://storage.googleapis.com/poapmedia/yam-heros-2020-logo-1597862089982.png'},
  {
    name: 'HackBFS Ideation Challenge',
    image: 'https://storage.googleapis.com/poapmedia/hackbfs-ideation-challenge-2020-logo-1597797792293.png'
  },
  {
    name: '/r/ethfinance 1-year Celebration',
    image: 'https://storage.googleapis.com/poapmedia/rethfinance-1-year-celebration-2020-logo-1597721158346.png'
  },
  {
    name: 'DappCraft "Moon Miner Show-Party"',
    image: 'https://storage.googleapis.com/poapmedia/dappcraft-moon-miner-show-party-2020-logo-1598533158687.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 9)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-9-2020-logo-1597595209129.png'
  },
  {
    name: 'ETHGlobal HackFS',
    image: 'https://storage.googleapis.com/poapmedia/ethglobal-hackfs-2020-logo-1597594972174.png'
  },
  {
    name: 'DeFi Brasil - Aavenomics Explained',
    image: 'https://storage.googleapis.com/poapmedia/defi-brasil-aavenomics-explained-2020-logo-1597156874223.png'
  },
  {
    name: 'DharmaCommunityBadgeAug2020',
    image: 'https://storage.googleapis.com/poapmedia/dharmacommunitybadgeaug2020-2020-logo-1597945865190.png'
  },
  {
    name: 'Rocket Pool Beta v2.5',
    image: 'https://storage.googleapis.com/poapmedia/rocket-pool-beta-v2-2020-logo-1596735657432.png'
  },
  {
    name: 'coinosis Curso desarrollo dApps en Ethereum',
    image: 'https://storage.googleapis.com/poapmedia/coinosis-curso-desarrollo-dapps-2020-logo-1596700585374.png'
  },
  {
    name: 'Decentraland - Rapture Gallery Opening',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-rapture-gallery-opening-2020-logo-1596622051443.png'
  },
  {
    name: 'Medalla Testnet Launch Call',
    image: 'https://storage.googleapis.com/poapmedia/medalla-testnet-launch-call-2020-logo-1596541547065.png'
  },
  {
    name: 'FriDAI Brunch #3',
    image: 'https://storage.googleapis.com/poapmedia/fridai-brunch-3-2020-logo-1596470967261.png'
  },
  {
    name: 'EthereumBA Asado -Agosto 2020-',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-asado-agosto-2020-2020-logo-1596396057464.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 8)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-8-2020-logo-1596376433387.png'
  },
  {
    name: 'PieDAO Community Call #11',
    image: 'https://storage.googleapis.com/poapmedia/piedao-community-call-11-2020-logo-1596121828119.png'
  },
  {
    name: 'ETHKL Community Meetup August 2020: Making Sense of MACI',
    image: 'https://storage.googleapis.com/poapmedia/ethkl-community-meetup-august-2020-making-sense-of-maci-2020-logo-1596080350232.png'
  },
  {
    name: 'EthereumBA 21st edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-21st-edition-2020-logo-1596042512115.png'
  },
  {
    name: 'Chicago Ethereum Meetup - [ONLINE] Help build Chicagos PoolTogether fork',
    image: 'https://storage.googleapis.com/poapmedia/chicago-ethereum-meetup-online-help-build-chicagos-pooltogether-fork-2020-logo-1595977881435.png'
  },
  {
    name: 'Zapper Community Call #0',
    image: 'https://storage.googleapis.com/poapmedia/zapper-community-call-0-2020-logo-1595622593966.png'
  },
  {
    name: 'TE Academy - Ecosystem Value Flows',
    image: 'https://storage.googleapis.com/poapmedia/te-academy-ecosystem-value-flows-2020-logo-1595616688303.png'
  },
  {
    name: 'IC3 Blockchain Camp 2020',
    image: 'https://storage.googleapis.com/poapmedia/ic3-blockchain-camp-2020-2020-logo-1595604617669.png'
  },
  {
    name: 'Decentraland - Summer Collection Fashion Show',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-summer-collection-fashion-show-2020-logo-1595534970919.png'
  },
  {
    name: 'Medalla Testnet Genesis Validator - Lodestar client',
    image: 'https://storage.googleapis.com/poapmedia/medalla-testnet-genesis-validator-lodestar-client-2020-logo-1595446056143.png'
  },
  {name: 'EDCON 2020', image: 'https://storage.googleapis.com/poapmedia/edcon-2020-2020-logo-1595424882494.png'},
  {
    name: 'test876876876876',
    image: 'https://storage.googleapis.com/poapmedia/test876876876876-2020-logo-1595365698938.png'
  },
  {
    name: 'Cryptograph Auction Participant - Maja Vujinovic',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-maja-vujinovic-2020-logo-1595330974729.png'
  },
  {
    name: 'Cryptograph Auction Participant - Rune Christensen',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-rune-christensen-2020-logo-1595330505135.png'
  },
  {
    name: 'Cryptograph Auction Participant - Kevin Owocki',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-kevin-owocki-2020-logo-1595329924972.png'
  },
  {
    name: 'Inmersión en Yield Farming - Crypto Plaza',
    image: 'https://storage.googleapis.com/poapmedia/inmersion-en-yield-farming-crypto-plaza-2020-logo-1595249583045.png'
  },
  {
    name: '521Party :The First Navigator Crypto Film Festival NFT',
    image: 'https://storage.googleapis.com/poapmedia/521party-the-first-navigator-crypto-film-festival-nft-2020-logo-1595221009183.png'
  },
  {
    name: '521 The First Navigator Crypto Film Festival  NFT',
    image: 'https://storage.googleapis.com/poapmedia/5212020-the-first-navigator-crypto-film-festival-nft-2020-logo-1595220843000.png'
  },
  {
    name: 'TE Academy - Introduction to Token Engineering',
    image: 'https://storage.googleapis.com/poapmedia/te-academy-introduction-to-token-engineering-2020-logo-1595030344209.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 7)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-7-2020-logo-1595022267195.png'
  },
  {
    name: 'TOURISTOKEN Birthday gift',
    image: 'https://storage.googleapis.com/poapmedia/touristoken-birthday-gift-2020-logo-1594951445476.png'
  },
  {
    name: 'CryptoMondays LA - July 20th',
    image: 'https://storage.googleapis.com/poapmedia/cryptomondays-la-72020-2020-logo-1594947115228.png'
  },
  {
    name: 'Decentraland Moonshot World Cup - Final',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-moonshot-world-cup-final-2020-logo-1594838781103.png'
  },
  {
    name: 'Decentraland Moonshot World Cup - Semi Final 2',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-moonshot-world-cup-semi-final-2-2020-logo-1594838692186.png'
  },
  {
    name: 'Decentraland Moonshot World Cup - Semi Final 1',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-moonshot-world-cup-semi-final-1-2020-logo-1594838604942.png'
  },
  {
    name: 'Ethereal Sessions #002',
    image: 'https://storage.googleapis.com/poapmedia/ethereal-sessions-002-2020-logo-1594826164534.png'
  },
  {
    name: 'Pirate Shower - Pre Release #1',
    image: 'https://storage.googleapis.com/poapmedia/pirate-shower-pre-release-1-2020-logo-1594780228393.png'
  },
  {
    name: 'Coaching and Emotional Support Platform SOS Token Winner',
    image: 'https://storage.googleapis.com/poapmedia/coaching-and-emotional-support-platform-sos-token-winner-2020-logo-1594731957426.png'
  },
  {
    name: 'Cryptograph Auction Participant - Jake Brukhman',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-jake-brukhman-2020-logo-1594731668450.png'
  },
  {
    name: 'Cryptograph Auction Participant - Roger Ver',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-roger-ver-2020-logo-1594731507575.png'
  },
  {
    name: 'Cryptograph Auction Participant - Mona El Isa',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-mona-el-isa-2020-logo-1594731246939.png'
  },
  {
    name: 'Cryptograph Auction Participant - Jeremy Gardner',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-jeremy-gardner-2020-logo-1594729572518.png'
  },
  {
    name: 'Cryptograph Auction Participant - Evan Van Ness',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-evan-van-ness-2020-logo-1594729329250.png'
  },
  {
    name: 'Cryptograph Auction Participant - Kathleen Breitman',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-kathleen-breitman-2020-logo-1594729074667.png'
  },
  {
    name: 'Ethereum Magicians Summer Sessions',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-magicians-summer-sessions-2020-logo-1594719966447.png'
  },
  {
    name: 'PieDAO Development Call #5',
    image: 'https://storage.googleapis.com/poapmedia/piedao-development-call-5-2020-logo-1594719431119.png'
  },
  {
    name: 'PieDAO Development Call #4',
    image: 'https://storage.googleapis.com/poapmedia/piedao-development-call-4-2020-logo-1594719362935.png'
  },
  {
    name: 'PieDAO Development Call #6',
    image: 'https://storage.googleapis.com/poapmedia/piedao-development-call-6-2020-logo-1594719225589.png'
  },
  {
    name: 'Panvala Summer Orientation',
    image: 'https://storage.googleapis.com/poapmedia/panvala-summer-orientation-2020-logo-1594659979021.png'
  },
  {
    name: 'Studio Nouveau Art Park and Gallery - Official Opening!',
    image: 'https://storage.googleapis.com/poapmedia/studio-nouveau-art-park-and-gallery-official-opening-2020-logo-1594659247231.png'
  },
  {name: 'Collaber', image: 'https://storage.googleapis.com/poapmedia/collaber-2020-logo-1594656090129.png'},
  {
    name: 'Genesis CoinGecko Candy Collector v2',
    image: 'https://storage.googleapis.com/poapmedia/genesis-coingecko-candy-collector-v2-2020-logo-1594612327646.png'
  },
  {
    name: 'Medalla Testnet Genesis Validator - Nimbus client',
    image: 'https://storage.googleapis.com/poapmedia/medalla-testnet-genesis-validator-nimbus-client-2020-logo-1595438166270.png'
  },
  {
    name: 'Medalla Testnet Genesis Validator - Lighthouse client',
    image: 'https://storage.googleapis.com/poapmedia/medalla-testnet-genesis-validator-lighthouse-client-2020-logo-1595438191547.png'
  },
  {
    name: 'Medalla Testnet Genesis Validator - Prysm client',
    image: 'https://storage.googleapis.com/poapmedia/medalla-testnet-genesis-validator-prysm-client-2020-logo-1595438201727.png'
  },
  {
    name: 'Medalla Testnet Genesis Validator - Teku client',
    image: 'https://storage.googleapis.com/poapmedia/medalla-testnet-genesis-validator-teku-client-2020-logo-1595438179329.png'
  },
  {
    name: 'DeFi Trading at the Networking Layer - Chicago DeFi x bloXroute x Kyber',
    image: 'https://storage.googleapis.com/poapmedia/defi-trading-at-the-networking-layer-chicago-defi-x-bloxroute-x-kyber-2020-logo-1594328082034.png'
  },
  {
    name: 'POAP y Crypto Plaza',
    image: 'https://storage.googleapis.com/poapmedia/poap-y-crypto-plaza-2020-logo-1594318520057.png'
  },
  {
    name: 'Grow Local SOS Token Winner',
    image: 'https://storage.googleapis.com/poapmedia/grow-local-sos-token-winner-2020-logo-1594210953935.png'
  },
  {
    name: 'Cryptograph Auction Participant - Jutta Steiner',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-jutta-steiner-2020-logo-1594154595557.png'
  },
  {
    name: 'Cryptograph Auction Participant - Eric Voorhees',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-eric-voorhees-2020-logo-1594154289672.png'
  },
  {
    name: 'Cryptograph Auction Participant - Mihai Alisie',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-mihai-alisie-2020-logo-1594153965410.png'
  },
  {
    name: 'Cryptograph Auction Participant - Alex Van de Sande',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-alex-van-de-sande-2020-logo-1594153598935.png'
  },
  {
    name: 'Cryptograph Auction Participant - Emin Gun Sirer',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-emin-gun-sirer-2020-logo-1594151231217.png'
  },
  {
    name: 'Cryptograph Auction Participant - Vlad Zamfir',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-vlad-zamfir-2020-logo-1594150676062.png'
  },
  {
    name: 'Cryptograph Auction Participant - Vitalik Buterin',
    image: 'https://storage.googleapis.com/poapmedia/cryptograph-auction-participant-vitalik-buterin-2020-logo-1594130078818.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 6)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-6-2020-logo-1593960029794.png'
  },
  {
    name: 'NON ZONE SOS Token Winner',
    image: 'https://storage.googleapis.com/poapmedia/non-zone-sos-token-winner-2020-logo-1593949424054.png'
  },
  {
    name: 'FACO SOS Token Winner',
    image: 'https://storage.googleapis.com/poapmedia/faco-sos-token-winner-2020-logo-1593869629496.png'
  },
  {
    name: 'Funding SOS Token Winner',
    image: 'https://storage.googleapis.com/poapmedia/funding-sos-token-winner-2020-logo-1593869226300.png'
  },
  {
    name: 'ImmunoLynk Dapp SOS Token Winner',
    image: 'https://storage.googleapis.com/poapmedia/immunolynk-dapp-sos-token-winner-2020-logo-1593869047294.png'
  },
  {
    name: 'Planet Wide SOS Hackathon Finalist',
    image: 'https://storage.googleapis.com/poapmedia/planet-wide-sos-hackathon-finalist-2020-logo-1593798159449.png'
  },
  {
    name: 'Reality Cards Beta Tester',
    image: 'https://storage.googleapis.com/poapmedia/reality-cards-beta-tester-2020-logo-1593702957006.png'
  },
  {
    name: 'Ethereal Sessions #001',
    image: 'https://storage.googleapis.com/poapmedia/ethereal-sessions-001-2020-logo-1593701183200.png'
  },
  {
    name: 'Decentraland - Dragon City Opening',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-dragon-city-opening-2020-logo-1593642923669.png'
  },
  {
    name: 'Festival Hackathon',
    image: 'https://storage.googleapis.com/poapmedia/festival-hackathon-2020-logo-1593630063500.png'
  },
  {
    name: 'FriDAI brunch #2 - The Potential of DeFi in India and Malaysia',
    image: 'https://storage.googleapis.com/poapmedia/fridai-brunch-2-the-potential-of-defi-in-india-and-malaysia-2020-logo-1593589468525.png'
  },
  {name: 'Crypto Plaza', image: 'https://storage.googleapis.com/poapmedia/crypto-plaza-2020-logo-1593529689051.png'},
  {
    name: 'FriDAI Brunch #2',
    image: 'https://storage.googleapis.com/poapmedia/fridai-brunch-2-2020-logo-1593528901835.png'
  },
  {
    name: 'Altona Testnet Launch Viewing ',
    image: 'https://storage.googleapis.com/poapmedia/altona-testnet-launch-viewing-2020-logo-1593374660994.png'
  },
  {
    name: 'Decentraland - Async Art After Party',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-async-art-after-party-2020-logo-1593293176177.png'
  },
  {
    name: 'Ethereum Summer Camp 2020 - Deep Dive into Eth 2.0 ',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-summer-camp-2020-logo-1594196642860.png'
  },
  {
    name: 'Ethereum Stakers Community Call #7',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-stakers-community-call-7-2020-logo-1593109631477.png'
  },
  {
    name: 'PieDAO Community Call #10',
    image: 'https://storage.googleapis.com/poapmedia/piedao-community-call-10-2020-logo-1593095939473.png'
  },
  {
    name: 'Ethereum Chicago & Buenos Aires Joint Meetup',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-chicago-buenos-aires-joint-meetup-2020-logo-1593094122519.png'
  },
  {name: 'Lux Triage', image: 'https://storage.googleapis.com/poapmedia/lux-triage-2020-logo-1593082666260.png'},
  {
    name: 'Genesis CoinGecko Candy Collector',
    image: 'https://storage.googleapis.com/poapmedia/genesis-coingecko-candy-collector-2020-logo-1592836513102.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 5)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-5-2020-logo-1592756241650.png'
  },
  {
    name: 'Ethereum Decentralized Finance (DeFi) by Madison Blockchain',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-decentralized-finance-defi-by-madison-blockchain-2020-logo-1592675052636.png'
  },
  {
    name: '1st Annual NFTY Pride',
    image: 'https://storage.googleapis.com/poapmedia/1st-annual-nfty-pride-2020-logo-1592519339951.png'
  },
  {
    name: 'Decentraland - Soho Plaza Launch Party',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-soho-plaza-launch-party-2020-logo-1592515540216.png'
  },
  {
    name: 'Decentraland - KnownOrigin Launch Party',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-knownorigin-launch-party-2020-logo-1592515428167.png'
  },
  {
    name: 'Decentraland - MakersPlace Gallery Opening',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-makersplace-gallery-opening-2020-logo-1592515340279.png'
  },
  {
    name: 'Decentraland - Chain Guardians Present the Art of NOI',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-chain-guardians-present-the-art-of-noi-2020-logo-1592515261380.png'
  },
  {
    name: 'Decentraland - PixelChain Party',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-pixelchain-party-2020-logo-1592514041212.png'
  },
  {
    name: 'Decentraland - Abner Harris Art Exhibition',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-abner-harris-art-exhibition-2020-logo-1592513927134.png'
  },
  {
    name: 'Decentraland - Mintbase Launch Party',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-mintbase-launch-party-2020-logo-1592511848031.png'
  },
  {
    name: 'Decentraland - OpenSea Art Mall Launch',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-opensea-art-mall-launch-2020-logo-1592511626391.png'
  },
  {
    name: 'Decentraland - SuperRare Museum Opening',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-superrare-museum-opening-2020-logo-1592511307909.png'
  },
  {
    name: 'Decentraland - Art Week Launch Party',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-art-week-launch-party-2020-logo-1592511211284.png'
  },
  {
    name: 'Decentraland Test event',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-test-event-2020-logo-1592485817067.png'
  },
  {
    name: 'PieDAO Development Call #3',
    image: 'https://storage.googleapis.com/poapmedia/piedao-development-call-3-2020-logo-1592317133925.png'
  },
  {
    name: 'BitcoinMalaysia Fastpass | 13 June 2020 - Certified by Blocklime',
    image: 'https://storage.googleapis.com/poapmedia/bitcoinmalaysia-fastpass-13-june-2020-certified-by-blocklime-2020-logo-1591966792184.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 4)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-4-2020-logo-1591544128756.png'
  },
  {
    name: 'BUIDL Honduras Charla Ethereum Ceutec 1',
    image: 'https://storage.googleapis.com/poapmedia/buidl-honduras-charla-ethereum-ceutec-1-2020-logo-1591401783131.png'
  },
  {
    name: 'PieDAO Rush Week - Beer and Pie',
    image: 'https://storage.googleapis.com/poapmedia/piedao-rush-week-beer-and-pie-2020-logo-1591352503725.png'
  },
  {
    name: 'PieDAO Community Call #9',
    image: 'https://storage.googleapis.com/poapmedia/piedao-community-call-9-2020-logo-1591352438513.png'
  },
  {
    name: 'Hack Money hosted by ETHGlobal',
    image: 'https://storage.googleapis.com/poapmedia/hack-money-hosted-by-ethglobal-2020-logo-1591198043098.png'
  },
  {name: 'Test POAP', image: 'https://storage.googleapis.com/poapmedia/test-poap-2020-logo-1591108115231.png'},
  {
    name: 'PieDAO Development Call #2',
    image: 'https://storage.googleapis.com/poapmedia/piedao-development-call-2-2020-logo-1591096645762.png'
  },
  {
    name: 'PieDAO Development Call #1',
    image: 'https://storage.googleapis.com/poapmedia/piedao-development-call-1-2020-logo-1591096595316.png'
  },
  {
    name: 'Ethereum Stakers Community Call #6',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-stakers-community-call-6-2020-logo-1590953318845.png'
  },
  {
    name: 'Intro to WBTC | Southeast Asia DeFi Week 05.06.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-wbtc-southeast-asia-defi-week-05062020-2020-logo-1590892633082.png'
  },
  {
    name: 'Intro to Aave | Southeast Asia DeFi Week 04.06.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-aave-southeast-asia-defi-week-04062020-2020-logo-1590852284791.png'
  },
  {
    name: 'Intro to Melon Protocol | Southeast Asia DeFi Week 03.06.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-melon-protocol-southeast-asia-defi-week-03062020-2020-logo-1590852218232.png'
  },
  {
    name: 'Intro to Kyber Network | Southeast Asia DeFi Week 02.06.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-kyber-network-southeast-asia-defi-week-02062020-2020-logo-1590852139995.png'
  },
  {
    name: 'Intro to MAKER | Southeast Asia DeFi Week 01.06.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-maker-southeast-asia-defi-week-01062020-2020-logo-1590852048613.png'
  },
  {
    name: 'FriDAI Brunch #1',
    image: 'https://storage.googleapis.com/poapmedia/fridai-brunch-1-2020-logo-1590754673900.png'
  },
  {
    name: 'Berlin Ethereum Meetup x ETHVR - V2',
    image: 'https://storage.googleapis.com/poapmedia/berlin-ethereum-meetup-x-ethvr-v2-2020-logo-1590684071941.png'
  },
  {
    name: 'Chicago Ethereum meetup - [ONLINE] Tokenized interest-earning, insured DAI',
    image: 'https://storage.googleapis.com/poapmedia/chicago-ethereum-meetup-online-tokenized-interest-earning-insured-dai-2020-logo-1590434663125.png'
  },
  {
    name: 'EthereumBA 19th edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-19th-edition-2020-logo-1595946426304.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 3)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-3-2020-logo-1590336312852.png'
  },
  {
    name: 'Muir Glacier Ethereum mainnet hard fork',
    image: 'https://storage.googleapis.com/poapmedia/muir-glacier-ethereum-mainnet-hard-fork-2020-logo-1589843256434.png'
  },
  {
    name: 'Ethereum Stakers Community Call #5',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-stakers-community-call-5-2020-logo-1589831752532.png'
  },
  {name: 'test', image: 'https://storage.googleapis.com/poapmedia/test-2020-logo-1589505588859.png'},
  {
    name: 'Decentraland test run',
    image: 'https://storage.googleapis.com/poapmedia/decentraland-test-run-2020-logo-1589479929316.png'
  },
  {
    name: 'ETHIndia Online: Chain Runner Hackathon',
    image: 'https://storage.googleapis.com/poapmedia/ethindia-online-chain-runner-hackathon-2020-logo-1589473066901.png'
  },
  {
    name: 'CryptoChicks Hatchery 2020',
    image: 'https://storage.googleapis.com/poapmedia/cryptochicks-hatchery-2020-2020-logo-1589324432721.png'
  },
  {
    name: 'Planet Wide SOS Hackathon',
    image: 'https://storage.googleapis.com/poapmedia/planet-wide-sos-hackathon-2020-logo-1589315332823.png'
  },
  {
    name: 'Introduction to Decentralized Finance (Online Webinar)',
    image: 'https://storage.googleapis.com/poapmedia/introduction-to-decentralized-finance-online-webinar-2020-logo-1589289671942.png'
  },
  {
    name: 'ETHKL Bitcoin Halving Special: An Exchange with Luno, Sinegy, and Tokenize',
    image: 'https://storage.googleapis.com/poapmedia/ethkl-bitcoin-halving-special-an-exchange-with-luno-sinegy-and-tokenize-2020-logo-1589206397770.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 2)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-2-2020-logo-1589119642123.png'
  },
  {
    name: 'Blockchain & COVID-19  |  Blockchain Village @ Medini (BV@M) Web Series 001',
    image: 'https://storage.googleapis.com/poapmedia/blockchain-covid-19-blockchain-village-medini-bvm-web-series-001-2020-logo-1588913927542.png'
  },
  {
    name: 'Intro to Hedera Hashgraph | Enterprise Blockchain Week 07.05.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-hedera-hashgraph-enterprise-blockchain-week-07052020-2020-logo-1588826872836.png'
  },
  {
    name: 'Intro to R3 Corda | Enterprise Blockchain Week 06.05.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-r3-corda-enterprise-blockchain-week-06052020-2020-logo-1588745604689.png'
  },
  {
    name: 'Ethereum.org Website contributor -  Content - 2019',
    image: 'https://storage.googleapis.com/poapmedia/ethereumorg-website-contributor-content-2019-2020-logo-1588700450748.png'
  },
  {
    name: 'Ethereum.org Website contributor -  Review - 2019',
    image: 'https://storage.googleapis.com/poapmedia/ethereumorg-website-contributor-reviews-2019-2020-logo-1588700398993.png'
  },
  {
    name: 'Ethereum.org Website contributor -  Code - 2019',
    image: 'https://storage.googleapis.com/poapmedia/ethereumorg-website-contributor-code-2019-2020-logo-1588700319443.png'
  },
  {
    name: 'Ethereum.org Website contributor -  Infrastructure - 2019',
    image: 'https://storage.googleapis.com/poapmedia/ethereumorg-website-contributor-infrastructure-2019-2020-logo-1588700258205.png'
  },
  {
    name: 'Ethereum.org Website contributor -  Translation - 2019',
    image: 'https://storage.googleapis.com/poapmedia/ethereumorg-website-contributor-translations-2019-2020-logo-1588700011666.png'
  },
  {
    name: 'Intro to Hyperledger | Enterprise Blockchain Week 05.05.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-hyperledger-enterprise-blockchain-week-05052020-2020-logo-1588645175232.png'
  },
  {
    name: 'Ethereal Summit',
    image: 'https://storage.googleapis.com/poapmedia/ethereal-summit-2020-logo-1588617327539.png'
  },
  {
    name: 'Intro to #STACS | Enterprise Blockchain Week 04.05.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-stacs-enterprise-blockchain-week-04052020-2020-logo-1588565983314.png'
  },
  {
    name: 'Ethereum Stakers Community Call #4',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-stakers-community-call-4-2020-logo-1588555879390.png'
  },
  {
    name: 'Alfredo Roisenzvit - UCEMA',
    image: 'https://storage.googleapis.com/poapmedia/alfredo-roisenzvit-ucema-2020-logo-1588534871813.png'
  },
  {
    name: 'Virtual Blockchain Week 2020 / Cointelegraph Official NFT',
    image: 'https://storage.googleapis.com/poapmedia/virtual-blockchain-week-2020-cointelegraph-official-nft-2020-logo-1588480439210.png'
  },
  {
    name: 'Virtual Blockchain Week 2020 VIP Commemorative NFT',
    image: 'https://storage.googleapis.com/poapmedia/virtual-blockchain-week-2020-vip-commemorative-nft-2020-logo-1588480321288.png'
  },
  {
    name: 'Virtual Blockchain Week 2020 - General',
    image: 'https://storage.googleapis.com/poapmedia/virtual-blockchain-week-2020-general-2020-logo-1588480219831.png'
  },
  {
    name: '#HackMoney Chainlink Developer Office Hours',
    image: 'https://storage.googleapis.com/poapmedia/hackmoney-chainlink-developer-office-hours-2020-logo-1588372421460.png'
  },
  {
    name: 'BitcoinMalaysia Fastpass | 02 May 2020 - Certified by Blocklime',
    image: 'https://storage.googleapis.com/poapmedia/bitcoinmalaysia-fastpass-02-may-2020-certified-by-blocklime-2020-logo-1588346519692.png'
  },
  {
    name: 'Bankless Member - 2020',
    image: 'https://storage.googleapis.com/poapmedia/bankless-class-of-2020-2020-logo-1588339171028.png'
  },
  {
    name: '#Staking4Fitness, cohort2',
    image: 'https://storage.googleapis.com/poapmedia/staking4fitness-cohort2-2020-logo-1588257776082.png'
  },
  {
    name: 'EthereumBA 18th edition',
    image: 'https://storage.googleapis.com/poapmedia/ethereumba-18th-edition-2020-logo-1595946340992.png'
  },
  {
    name: '#Staking4Fitness, cohort1',
    image: 'https://storage.googleapis.com/poapmedia/staking4fitness-cohort1-2020-logo-1588243202700.png'
  },
  {
    name: 'Intro to Alibaba Cloud | Enterprise Blockchain Week 01.05.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-alibaba-cloud-enterprise-blockchain-week-01052020-2020-logo-1588175003121.png'
  },
  {
    name: 'Intro to AntChain | Enterprise Blockchain Week 30.04.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-antchain-enterprise-blockchain-week-30042020-2020-logo-1588173695835.png'
  },
  {
    name: 'Intro to Quorum | Enterprise Blockchain Week 29.04.2020',
    image: 'https://storage.googleapis.com/poapmedia/intro-to-quorum-enterprise-blockchain-week-29042020-2020-logo-1588129586043.png'
  },
  {
    name: 'PIE DAO Community Call #8',
    image: 'https://storage.googleapis.com/poapmedia/pie-dao-community-call-8-2020-logo-1588060200564.png'
  },
  {
    name: 'ETHSantiago[03]: ZkSnark in a nutshell',
    image: 'https://storage.googleapis.com/poapmedia/ethsantiago03-zksnark-in-a-nutshell-2020-logo-1588052977185.png'
  },
  {
    name: 'ETH Network - Attendee (Gen 1)',
    image: 'https://storage.googleapis.com/poapmedia/eth-network-attendee-gen-1-2020-logo-1587910361725.png'
  },
  {
    name: 'AlphaWallet Ephemeral',
    image: 'https://storage.googleapis.com/poapmedia/alphawallet-ephemeral-2020-logo-1587849517095.png'
  },
  {
    name: 'ETHTURIN 2020 - Team',
    image: 'https://storage.googleapis.com/poapmedia/ethturin-2020-team-2020-logo-1587731396379.png'
  },
  {
    name: 'ETHTURIN 2020 - Speaker',
    image: 'https://storage.googleapis.com/poapmedia/ethturin-2020-speaker-2020-logo-1587731362163.png'
  },
  {
    name: 'ETHTURIN 2020 - Mentor',
    image: 'https://storage.googleapis.com/poapmedia/ethturin-2020-mentor-2020-logo-1587731329905.png'
  },
  {
    name: 'ETHTURIN 2020 - Hacker',
    image: 'https://storage.googleapis.com/poapmedia/ethturin-2020-hacker-2020-logo-1587731281667.png'
  },
  {
    name: 'ETHTURIN 2020 - Attendee',
    image: 'https://storage.googleapis.com/poapmedia/ethturin-2020-attendee-2020-logo-1587731221822.png'
  },
  {
    name: 'ETHSantiago [02]: Data y privacidad en tiempos de pandemia',
    image: 'https://storage.googleapis.com/poapmedia/ethsantiago-02-data-y-privacidad-en-tiempos-de-pandemia-2020-logo-1587664872171.png'
  },
  {
    name: 'Chicago Ethereum meetup - Using smart contracts in L2 with optimistic rollup',
    image: 'https://storage.googleapis.com/poapmedia/chicago-ethereum-meetup-using-smart-contracts-in-l2-with-optimistic-rollup-2020-logo-1587655866103.png'
  },
  {
    name: 'PIE DAO Community Call #7',
    image: 'https://storage.googleapis.com/poapmedia/pie-dao-community-call-7-2020-logo-1587483140612.png'
  },
  {
    name: 'PIE DAO Community Call #6',
    image: 'https://storage.googleapis.com/poapmedia/pie-dao-community-call-6-2020-logo-1587139641328.png'
  },
  {
    name: 'Prysmatic Labs Topaz Testnet Launch party',
    image: 'https://storage.googleapis.com/poapmedia/prysmatic-labs-topaz-launch-party-2020-logo-1587073560765.png'
  },
  {
    name: 'Philly Blockchain Tech April 2020',
    image: 'https://storage.googleapis.com/poapmedia/philly-blockchain-tech-april-2020-2020-logo-1586980328616.png'
  },
  {
    name: 'Dragon Quest - Grandmaster',
    image: 'https://storage.googleapis.com/poapmedia/dragon-quest-grandmaster-2020-logo-1586902059797.png'
  },
  {
    name: 'Dragon Quest - Sponsor',
    image: 'https://storage.googleapis.com/poapmedia/dragon-quest-sponsor-2020-logo-1586902028043.png'
  },
  {
    name: 'Dragon Quest - Hacker',
    image: 'https://storage.googleapis.com/poapmedia/dragon-quest-hacker-2020-logo-1586901993341.png'
  },
  {
    name: 'ETHSantiago [01]: DeFi en tiempos de crisis por Camila Russo',
    image: 'https://storage.googleapis.com/poapmedia/eth-santiago-defi-en-tiempos-de-crisis-camila-russo-2020-logo-1586879467903.png'
  },
  {
    name: 'Panvala Spring Orientation',
    image: 'https://storage.googleapis.com/poapmedia/panvala-spring-orientation-2020-logo-1586542906760.png'
  },
  {
    name: 'BitcoinMalaysia Fastpass | 11 April 2020 - Certified by Blocklime',
    image: 'https://storage.googleapis.com/poapmedia/bitcoinmalaysia-fastpass-11-april-2020-certified-by-blocklime-2020-logo-1586448546007.png'
  },
  {
    name: 'DEFI - Finanzas Descentralizadas - Livestreaming 13 Mayo 2020',
    image: 'https://storage.googleapis.com/poapmedia/defi-finanzas-descentralizadas-livestreaming-29-abril-2020-2020-logo-1586286422818.png'
  },
  {
    name: 'DEFI - Finanzas Descentralizadas - Livestreaming 29 Abril 2020',
    image: 'https://storage.googleapis.com/poapmedia/defi-finanzas-descentralizadas-livestreaming-22-abril-2020-2020-logo-1586286380664.png'
  },
  {
    name: 'DEFI - Finanzas Descentralizadas - Livestreaming 22 Abril 2020',
    image: 'https://storage.googleapis.com/poapmedia/defi-finanzas-descentralizadas-livestreaming-15-abril-2020-2020-logo-1586286334672.png'
  },
  {
    name: 'DEFI - Finanzas Descentralizadas - Livestreaming 8 Abril 2020',
    image: 'https://storage.googleapis.com/poapmedia/defi-finanzas-descentralizadas-livestreaming-8-abril-2020-2020-logo-1586286277317.png'
  },
  {
    name: 'Lanzamiento ecosistema Ethereum Santiago [Bloque Génesis]',
    image: 'https://storage.googleapis.com/poapmedia/eth-santiago-lanzamiento-ethereum-santiago-bloque-genesis-2020-logo-1586032538422.png'
  },
  {
    name: 'NONCON 2020- Volunteer',
    image: 'https://storage.googleapis.com/poapmedia/noncon-2020-volunteer-2020-logo-1585832687968.png'
  },
  {
    name: 'NONCON 2020 - Organizer',
    image: 'https://storage.googleapis.com/poapmedia/noncon-2020-organizer-2020-logo-1585832651140.png'
  },
  {
    name: 'NONCON 2020 - Speaker',
    image: 'https://storage.googleapis.com/poapmedia/noncon-2020-speaker-2020-logo-1585832620725.png'
  },
  {
    name: 'NONCON 2020 - Atendee',
    image: 'https://storage.googleapis.com/poapmedia/noncon-2020-atendee-2020-logo-1585832497118.png'
  },
  {
    name: 'NON CON Bitbox02 Shift',
    image: 'https://storage.googleapis.com/poapmedia/non-con-bitbox02-shift-2020-logo-1585831861949.png'
  },
  {
    name: 'NON CON raffle nft 2020',
    image: 'https://storage.googleapis.com/poapmedia/non-con-raffle-nft-2020-2020-logo-1585831543187.png'
  },
  {
    name: 'MetaClan Breeding Tournament',
    image: 'https://storage.googleapis.com/poapmedia/metaclan-breeding-tournament-2020-logo-1585691478538.png'
  },
  {
    name: 'Solidity Summit 2020',
    image: 'https://storage.googleapis.com/poapmedia/solidity-summit-2020-2020-logo-1585683482643.png'
  },
  {name: 'NONCON 2020', image: 'https://storage.googleapis.com/poapmedia/noncon-2020-2020-logo-1585683163798.png'},
  {
    name: 'DEFI - Finanzas Descentralizadas - Livestreaming 1º Abril 2020',
    image: 'https://storage.googleapis.com/poapmedia/defi-finanzas-descentralizadas-livestreaming-marzo-2020-2020-logo-1585521623535.png'
  },
  {
    name: 'ETH Buenos Aires Meetup: Black Thursday + MakerDAO Backstop Syndicate',
    image: 'https://storage.googleapis.com/poapmedia/eth-buenos-aires-meetup-black-thursday-makerdao-backstop-syndicate-2020-logo-1585237703766.png'
  },
  {
    name: 'Prueba Maker LatinAmerica Meetup',
    image: 'https://storage.googleapis.com/poapmedia/prueba-maker-latinamerica-meetup-2020-logo-1585149530116.png'
  },
  {
    name: 'MKR Backstop Syndicate',
    image: 'https://storage.googleapis.com/poapmedia/mkr-backstop-syndicate-2020-logo-1585065028112.png'
  },
  {name: 'EthVR0', image: 'https://storage.googleapis.com/poapmedia/ethvr0-2020-logo-1585048928549.png'},
  {
    name: 'Devcon 5 Scholar',
    image: 'https://storage.googleapis.com/poapmedia/devcon-5-scholar-2020-logo-1584622035537.png'
  },
  {
    name: 'Bad Crypto Podcast Episode #383 with Ben Swann',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-383-with-ben-swann-2020-logo-1584476312239.png'
  },
  {
    name: 'Bad Crypto Podcast Episode #382 with Dr. Ron Paul',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-382-with-dr-ron-paul-2020-logo-1584248987349.png'
  },
  {
    name: 'InterCon - Dreamer',
    image: 'https://storage.googleapis.com/poapmedia/intercon-dreamer-2020-logo-1584404871132.png'
  },
  {
    name: 'InterCon - Mission Control',
    image: 'https://storage.googleapis.com/poapmedia/intercon-ground-control-2020-logo-1584405038832.png'
  },
  {
    name: 'InterCon - Cosmonaut',
    image: 'https://storage.googleapis.com/poapmedia/intercon-cosmonaut-2020-logo-1584404831531.png'
  },
  {
    name: 'Bad Crypto Podcast Episode #380 featuring NFT.NYC',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-380-featuring-nftnyc-2020-logo-1584227633736.png'
  },
  {
    name: 'Bad Crypto Podcast Episode #379 with Vitalik Buterin ',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-379-with-vitalik-buterin-2020-logo-1584227551287.png'
  },
  {
    name: 'Blockchain Law - Rutgers University',
    image: 'https://storage.googleapis.com/poapmedia/blockchain-law-rutgers-university-2020-logo-1583797613048.png'
  },
  {name: 'ETHParis 2020', image: 'https://storage.googleapis.com/poapmedia/ethparis-2020-2020-logo-1583596060330.png'},
  {
    name: 'Rutgers Blockchain Hub & eToro',
    image: 'https://storage.googleapis.com/poapmedia/rutgers-blockchain-hub-etoro-2020-logo-1583195999829.png'
  },
  {
    name: 'Bad Crypto Podcast Episode 376 with Jason BTO',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-376-with-jason-bto-2020-logo-1583008157056.png'
  },
  {
    name: 'Bad Crypto Podcast Episode #377',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-377-2020-logo-1582860365055.png'
  },
  {
    name: 'deprecated - Bad Crypto Podcast Episode 376 - Jason BTO',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-376-jason-bto-2020-logo-1582860289746.png'
  },
  {
    name: 'Ethereum Buenos Aires Meetup - Feb 2020',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-buenos-aires-meetup-feb-2020-2020-logo-1582836622151.png'
  },
  {
    name: 'Evening with Aztec, Maker and Melon',
    image: 'https://storage.googleapis.com/poapmedia/evening-with-aztec-maker-and-melon-2020-logo-1582717749947.png'
  },
  {
    name: 'JUGF - JDK 13 und GraalVM im Java Ecosystem',
    image: 'https://storage.googleapis.com/poapmedia/jugf-jdk-13-und-graalvm-im-java-ecosystem-2020-logo-1582708868200.png'
  },
  {
    name: 'Accenture Blockchain @ Rutgers',
    image: 'https://storage.googleapis.com/poapmedia/accenture-blockchain-rutgers-2020-logo-1582579593981.png'
  },
  {
    name: 'Rutgers Blockchain Workshop 101',
    image: 'https://storage.googleapis.com/poapmedia/rutgers-blockchain-workshop-101-2020-logo-1581970792345.png'
  },
  {
    name: 'Kickback online - the genesis',
    image: 'https://storage.googleapis.com/poapmedia/ethdenver-digital-maker-space-2020-logo-1581806405736.png'
  },
  {
    name: 'Chicago Ethereum Meetup - Dogfooding Ethereum: Internal dapp pilot',
    image: 'https://storage.googleapis.com/poapmedia/chicago-meetup-2020-logo-1581466126598.png'
  },
  {
    name: 'deprecated Chicago Ethereum Meetup - Dogfooding Ethereum: Internal dapp pilot',
    image: 'https://storage.googleapis.com/poapmedia/chicago-ethereum-meetup-dogfooding-ethereum-internal-dapp-pilot-2020-logo-1581465816542.png'
  },
  {
    name: 'Student Opportunities in Blockchain',
    image: 'https://storage.googleapis.com/poapmedia/student-opportunities-in-blockchain-2020-logo-1581289862953.png'
  },
  {
    name: 'ETHLondon - 2020',
    image: 'https://storage.googleapis.com/poapmedia/ethlondon-2020-2020-logo-1581125652756.png'
  },
  {name: 'EthCC 3', image: 'https://storage.googleapis.com/poapmedia/ethcc-3-2020-logo-1581124649669.png'},
  {
    name: 'UltraVitalik MegaPegaBufficorn - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/ultravitalik-megapegabufficorn-ethdenver-2020-2020-logo-1581548557063.png'
  },
  {
    name: 'MegaPegaBufficorn - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/megapegabufficorn-ethdenver-2020-2020-logo-1581548641298.png'
  },
  {
    name: 'Press - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/press-ethdenver-2020-2020-logo-1581549391967.png'
  },
  {
    name: 'Steward - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/steward-2020-logo-1581548574843.png'
  },
  {
    name: 'Volunteer - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/volunteer-ethdenver-2020-2020-logo-1581548603801.png'
  },
  {
    name: 'Stakeholder - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/stakeholder-ethdenver-2020-2020-logo-1581548537656.png'
  },
  {
    name: 'Staked - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/staked-ethdenver-2020-2020-logo-1581548589569.png'
  },
  {
    name: 'Attendee - ETHDenver 2020',
    image: 'https://storage.googleapis.com/poapmedia/attendee-ethdenver-2020-2020-logo-1581548656564.png'
  },
  {
    name: 'Proof: Cryptocurrency For Commerce',
    image: 'https://storage.googleapis.com/poapmedia/buidl-week-colorado-2020-2020-logo-1581025054851.png'
  },
  {
    name: 'Bad Crypto Podcast Episode 367 - Samson Williams',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-367-samson-williams-2020-logo-1580919618251.png'
  },
  {
    name: 'MetaCartel Invades Denver: Multisig & Friends',
    image: 'https://storage.googleapis.com/poapmedia/metacartel-invades-denver-multisig-friends-2020-logo-1580780316266.png'
  },
  {
    name: 'Blockchain 101 - Rutgers',
    image: 'https://storage.googleapis.com/poapmedia/blockchain-101-rutgers-2020-logo-1580775887936.png'
  },
  {name: 'Pie OG', image: 'https://storage.googleapis.com/poapmedia/pie-og-2020-logo-1580421212029.png'},
  {
    name: 'Bad Crypto Podcast Episode 364 - Peter McCormack',
    image: 'https://storage.googleapis.com/poapmedia/bad-crypto-podcast-episode-364-peter-mccormack-2020-logo-1580409297727.png'
  },
  {
    name: 'Rutgers Intro event 2020',
    image: 'https://storage.googleapis.com/poapmedia/rutgers-intro-event-2020-2020-logo-1580333907036.png'
  },
  {
    name: 'Crypto-asado 2020',
    image: 'https://storage.googleapis.com/poapmedia/crypto-asado-2020-2020-logo-1580134661063.png'
  },
  {name: 'UtkalHacks 2.0', image: 'https://storage.googleapis.com/poapmedia/utkalhacks-20-2020-logo-1579908488205.png'},
  {
    name: 'Deprecated event',
    image: 'https://storage.googleapis.com/poapmedia/ramiro-gonzalez-2-2020-logo-1580308698156.png'
  },
  {
    name: 'Deprecated event',
    image: 'https://storage.googleapis.com/poapmedia/ramiro-gonzalez-2020-logo-1580308674673.png'
  },
  {name: 'やさしいDeFi#3: DeFiレンディングとリスク', image: 'https://storage.googleapis.com/poapmedia/defi-3-logo-2020.png'},
  {
    name: 'Ethereum Buenos Aires meetup - Enero 2020',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-buenos-aires-meetup---enero-2020-2020-logo-1579011022288.png'
  },
  {name: 'Ethereum on tap #1', image: 'https://storage.googleapis.com/poapmedia/ethereum-on-tap.png'},
  {
    name: 'Chicago DeFi Meetup',
    image: 'https://storage.googleapis.com/poapmedia/chicago-defi-meetup-2020-logo-1578867600317.png'
  },
  {
    name: 'North American Bitcoin Conference 2020',
    image: 'https://storage.googleapis.com/poapmedia/north-american-bitcoin-conference-2020-2020-logo-1578623458954.png'
  },
  {
    name: 'JUGF - Komplexes verständlich machen und wirkungsvoll kommunizieren im technischen Umfeld',
    image: 'https://storage.googleapis.com/poapmedia/jugf---komplexes-verstandlich-machen-und-wirkungsvoll-kommunizieren-im-technischen-umfeld-2020-logo-1578563509191.png'
  },
  {
    name: 'The Joel Comm Show',
    image: 'https://storage.googleapis.com/poapmedia/testing-event-2020-logo-1578504664378.png'
  },
  {name: 'WashingtonElite', image: 'https://storage.googleapis.com/poapmedia/joel-comm-2020-logo-1578448442611.png'},
  {
    name: '36th Chaos Communication Congress',
    image: 'https://storage.googleapis.com/poapmedia/36th-chaos-communication-congress-2019-logo-1577356908767.png'
  },
  {
    name: 'Coindesk Test Run',
    image: 'https://storage.googleapis.com/poapmedia/coindesk-test-run-2019-logo-1576787205820.png'
  },
  {
    name: 'Ethereum Buenos Aires meetup - Diciembre 2019 (II)',
    image: 'https://storage.googleapis.com/poapmedia/ethereum-buenos-aires-meetup---diciembre-2019-(ii)-2019-logo-1576706365931.png'
  },
  {
    name: 'Earn crypto for freelance work and quadratic voting / CLR with Gitcoin - 2019',
    image: 'https://www.poap.xyz/events/badges/chicago-dic.png'
  },
  {
    name: 'Earn crypto for freelance work and quadratic voting / CLR with Gitcoin',
    image: 'https://www.poap.xyz/events/badges/chicago-dic.png'
  },
  {name: 'Decentralized Organizations Meetup', image: 'https://www.poap.xyz/events/badges/personhood-dic.png'},
  {
    name: 'Ethereum Meetup December - Identity Session',
    image: 'https://www.poap.xyz/events/badges/berlin-meetup-dic.png'
  },
  {name: 'Ethereum Buenos Aires - 2019', image: 'https://www.poap.xyz/events/badges/eth-ba-dic.png'},
  {
    name: 'Hackathon Muñón + Gitcoin = Global Communities',
    image: 'https://www.poap.xyz/events/badges/el-hackaton-2019.png'
  },
  {
    name: 'Hackathon Muñón + Gitcoin = Global Communities',
    image: 'https://www.poap.xyz/events/badges/el-hackaton-2019.png'
  },
  {name: 'Decentralized Finance & Governance ', image: 'https://www.poap.xyz/events/badges/nz-tour-2019.png'},
  {name: 'Decentralized Finance & Governance ', image: 'https://www.poap.xyz/events/badges/nz-tour-2019.png'},
  {name: 'Decentralized Finance & Governance', image: 'https://www.poap.xyz/events/badges/nz-tour-2019.png'},
  {name: 'Into the Interchain with Cosmos', image: 'https://www.poap.xyz/events/badges/phila-meetup.png'},
  {name: 'CONFLUX', image: 'https://www.poap.xyz/events/badges/conflux-2019.png'},
  {name: 'ETHWaterloo 2019', image: 'https://www.poap.xyz/events/badges/waterloo-qr-2019.png.png'},
  {name: 'ETHWaterloo 2019', image: 'https://www.poap.xyz/events/badges/waterloo-unlock-2019.png'},
  {
    name: 'Smart contract insurance with Nexus Mutual',
    image: 'https://www.poap.xyz/events/badges/meetup-nexus-chicago-111919.png'
  },
  {name: 'Ethereum Buenos Aires', image: 'https://www.poap.xyz/events/badges/ba-oct.png'},
  {name: 'Ethereum London Meetup 2019', image: 'https://www.poap.xyz/events/badges/ethlondon2019.png'},
  {name: 'iExec Off-Chain Drinks', image: 'https://www.poap.xyz/events/badges/iexec-osaka.png'},
  {name: 'Devcon2 by Piper Merriam', image: 'https://www.poap.xyz/events/badges/devcon2-piper.png'},
  {name: 'Devcon5', image: 'https://www.poap.xyz/events/badges/devcon5.png'},
  {name: 'Osaka Blockchain Week', image: 'https://www.poap.xyz/events/badges/obw-2019.png'},
  {name: 'Geneva Bonfire Cryptobrunch', image: 'https://www.poap.xyz/events/badges/bonfire-cryptobrunch.png'},
  {name: 'TEL AVIV BLOCKCHAIN WEEK 2019', image: 'https://www.poap.xyz/events/badges/tlvbw-2019.png'},
  {name: 'DeFi Summit London 2019', image: 'https://www.poap.xyz/events/badges/DeFi-summit-london-2019.png'},
  {name: 'ETHBOSTON 2019', image: 'https://www.poap.xyz/events/badges/ethboston-2019-hand.png'},
  {name: 'ETHBOSTON 2019', image: 'https://www.poap.xyz/events/badges/ethboston-2019-card.png'},
  {name: 'ETHBOSTON 2019', image: 'https://www.poap.xyz/events/badges/ethboston-2019-kickback.png'},
  {
    name: 'Ethereum Buenos Aires Meetup - Agosto 2019',
    image: 'https://www.poap.xyz/events/badges/ethba-meetup-ago-2019.png'
  },
  {name: 'ETHBerlinZwei', image: 'https://www.poap.xyz/events/badges/ethberlinzwei.png'},
  {name: 'DAOfest', image: 'https://www.poap.xyz/events/badges/dao-fest-berlin-2019.png'},
  {name: 'MetaCartel Demo Day 2019', image: 'https://www.poap.xyz/events/badges/metacartel-demo-day-2019.png'},
  {name: 'Berlin Blockchain week - 2019', image: 'https://www.poap.xyz/events/badges/blockchain-week-berlin-2019.png'},
  {name: 'Blockchain Week Berlin ', image: 'https://www.poap.xyz/events/badges/blockchain-week-berlin19.png'},
  {
    name: 'やさしいDeFi#3: DeFiレンディングとリスク',
    image: 'https://storage.googleapis.com/poapmedia/test-maker-2020-logo-1579569025794.png'
  },
  {name: 'Dappcon 2019', image: 'https://storage.googleapis.com/poapmedia/Dappcon-19-logo-1588008797682.png'},
  {name: 'DEFCON 27', image: 'https://www.poap.xyz/events/badges/DEFCON_27.jpg'},
  {name: 'EthIndia2', image: 'https://www.poap.xyz/events/badges/EthIndiaHackathon2.png'},
  {name: 'EthBuenosAires', image: 'https://www.poap.xyz/events/badges/Ethba-meetup-jul-2019.png'},
  {
    name: 'Ethereum Buenos Aires Meetup - Junio 2019',
    image: 'https://www.poap.xyz/events/badges/ethba-meetup-jun-2019.png'
  },
  {name: 'Zcon1', image: 'https://www.poap.xyz/events/badges/zcon1.png'},
  {name: 'Zcon0', image: 'https://www.poap.xyz/events/badges/zcon0.png'},
  {name: 'Blocumenta Blockathon Sydney 2', image: 'https://www.poap.xyz/events/badges/TZxncZi.png'},
  {name: 'Ethereum Buenos Aires Meetup', image: 'https://www.poap.xyz/events/badges/ethba-meetup-0519.png'},
  {name: 'ETHNewYork', image: 'https://www.poap.xyz/events/badges/ethny-19.png'},
  {name: 'ETHCAPETOWN', image: 'https://www.poap.xyz/events/badges/ethcapetown-19.png'},
  {name: 'EDCON', image: 'https://www.poap.xyz/events/badges/edcon-19.png'},
  {name: 'EDCON HACKATHON', image: 'https://www.poap.xyz/events/badges/edcon-hackathon-19.png'},
  {name: 'ETHParis', image: 'https://www.poap.xyz/events/badges/ethparis-19.png'},
  {name: 'EthCC', image: 'https://www.poap.xyz/events/badges/ethcc-18-pre.png'},
  {name: 'Casa Bonita', image: 'https://www.poap.xyz/events/badges/casa-bonita-19.png'},
  {name: 'TruffleCon', image: 'https://www.poap.xyz/events/badges/trufflecon-18.png'},
  {name: 'Token Wirtschaft', image: 'https://www.poap.xyz/events/badges/token-wirtschaft-18.png'},
  {name: 'RaveCon0', image: 'https://www.poap.xyz/events/badges/ravecon0.png'},
  {name: 'Nifty', image: 'https://www.poap.xyz/events/badges/nifty-18.png'},
  {name: 'ETHSanFrancisco', image: 'https://www.poap.xyz/events/badges/ethsf-18.png'},
  {name: 'ETHMemphis', image: 'https://www.poap.xyz/events/badges/eth-memphis-18.png'},
  {name: 'ETHIndia', image: 'https://www.poap.xyz/events/badges/ethindia-18.png'},
  {name: 'ETHDenver', image: 'https://www.poap.xyz/events/badges/ethdenver-19.png'},
  {name: 'Aracon', image: 'https://www.poap.xyz/events/badges/aracon-19.png'},
  {name: 'ETHDenver', image: 'https://www.poap.xyz/events/badges/ethdenver-18.png'},
  {name: 'EthCC', image: 'https://www.poap.xyz/events/badges/ethcc-18.png'},
  {name: 'ETHBuenosAires', image: 'https://www.poap.xyz/events/badges/ethbsas-18.png'},
  {name: 'ETHBerlin', image: 'https://www.poap.xyz/events/badges/ethberlin-18.png'},
  {name: 'ETHAtlanta', image: 'https://www.poap.xyz/events/badges/ethatlanta-18.png'},
  {name: 'EDCON', image: 'https://www.poap.xyz/events/badges/edcon-18.png'},
  {name: 'DevCon4', image: 'https://www.poap.xyz/events/badges/devcon4.png'},
  {name: 'DevCon3', image: 'https://www.poap.xyz/events/badges/devcon3.png'},
  {name: 'DevCon2', image: 'https://www.poap.xyz/events/badges/devcon2.png'},
  {name: 'DevCon1', image: 'https://www.poap.xyz/events/badges/devcon1.png'},
  {name: '#DeFi Summit', image: 'https://www.poap.xyz/events/badges/defi-summit-18.png'},
  {name: 'DappCon', image: 'https://www.poap.xyz/events/badges/dappcon-18.png'},
]
