import { usePrivy, useWallets } from '@privy-io/react-auth'

const useSignMessage = () => {
  const { authenticated } = usePrivy();
  const { wallets } = useWallets();

  const signMessage = async(message: string): Promise<string> => {
    if (!authenticated) {
      throw Error;
    }

    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    const address = wallet.address;

    return await provider.request({
      method: 'personal_sign',
      params: [message, address],
    });
  };

  return { signMessage };
};

export default useSignMessage;
