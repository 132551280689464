import { usePrivy } from '@privy-io/react-auth';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Constants
import { ROUTES } from 'lib/helpers/constants';

// UI
import MainLayout from 'ui/layouts/MainLayout';
import { usePoapsQueryContext } from '../../lib/hooks/usePoapsQuery';
import Hero from './components/Hero';

const Home: FC = () => {
  const { push } = useHistory();
  const { authenticated, login } = usePrivy();
  const { isFetchingPoaps } = usePoapsQueryContext();

  useEffect(() => {
    if (authenticated && !isFetchingPoaps) push(ROUTES.userSignature);
  }, [authenticated, isFetchingPoaps, push]);

  return (
    <MainLayout>
      <Hero
        connectAction={login}
        isConnected={authenticated}
        isLoading={isFetchingPoaps}
      />
    </MainLayout>
  )
};

export default Home;
