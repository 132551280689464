import { UserPoap } from 'lib/types';

import {
  POAPsForCollectorsCompassData,
  POAPS_FOR_COLLECTOR,
} from './queries/token'

const REACT_APP_COMPASS_BASE_URL = process.env.REACT_APP_COMPASS_BASE_URL as string

export const getPOAPsForCollector = async (address: string,): Promise<UserPoap[]> => {
  if (!address) return [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const where: { [key: string]: any } = {
    collector_address: { _eq: address.toLowerCase() },
  }

  const params = {
    body: JSON.stringify({
      query: POAPS_FOR_COLLECTOR,
      variables: {
        where,
      },
    }),
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
  }

  try {
    const res = await fetch(REACT_APP_COMPASS_BASE_URL, params)

    if (!res.ok) {
      const json = await res.json()

      throw json.message || `There was a problem retrieving poaps for collector ${address.toLowerCase()}`
    }

    const response: POAPsForCollectorsCompassData = await res.json()

    return response.data.poaps
  } catch (e: unknown) {
    console.error(e)
    return []
  }
}
