import React from 'react';
import ReactDOM from 'react-dom/client';
import { Global } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';

// Routes
import Routes from 'lib/routes';

// Hooks
import { PrivyProvider } from '@privy-io/react-auth'
import { PoapsQueryProvider } from './lib/hooks/usePoapsQuery';

// Styles
import { mainStyles, mainFonts } from 'lib/styles';

import * as buffer from 'buffer';

// make sure Buffer is available for @privy-io/react-auth
window.Buffer = buffer.Buffer;

// Configs
const queryClient = new QueryClient();

const PRIVY_APP_ID = process.env.REACT_APP_PRIVY_APP_ID as string

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <QueryClientProvider client={queryClient}>
    <PrivyProvider appId={PRIVY_APP_ID} config={{ loginMethods: ['wallet'] }}>
      <PoapsQueryProvider>
        <Routes />

        <Global styles={mainStyles} />

        <Global styles={mainFonts} />
      </PoapsQueryProvider>
    </PrivyProvider>
  </QueryClientProvider>,
);
